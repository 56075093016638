/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-vars */
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Order.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2022/03/30 Rayoo)li : 新規作成
 *
 * Summary Order画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
 import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
 import moment from "moment";
 import React, { useCallback, useEffect, useReducer, useState } from "react";
 import Grid from "@material-ui/core/Grid";
 import { makeStyles, withStyles } from "@material-ui/core/styles";
 import Button from "@material-ui/core/Button";
 import { red } from "@material-ui/core/colors";
 import Table from "@material-ui/core/Table";
 import TableBody from "@material-ui/core/TableBody";
 import TableCell from "@material-ui/core/TableCell";
 import TableContainer from "@material-ui/core/TableContainer";
 import TableRow from "@material-ui/core/TableRow";
 import Checkbox from "@material-ui/core/Checkbox";
 import Autocomplete from '@mui/material/Autocomplete';
 import TextField from '@mui/material/TextField';
 import FormControlLabel from "@material-ui/core/FormControlLabel";
 import Dialog from "@material-ui/core/Dialog";
 import DialogActions from "@material-ui/core/DialogActions";
 import DialogContent from "@material-ui/core/DialogContent";
 import DialogTitle from "@material-ui/core/DialogTitle";
 import { useLocation } from "react-router-dom";
 import { MuiPickersUtilsProvider } from '@material-ui/pickers';
 import { DatePicker } from '@material-ui/pickers';
 import DateFnsUtils from '@date-io/date-fns';
 import jaLocale from 'date-fns/locale/ja';
 import { v4 as uuidv4 } from "uuid";
 import qs from 'qs';
 import {
   queryArPriceInfosByQueryIDIndex4,
   queryArPriceInfosByQueryIDIndex3,
   queryArFukuiWHSeinoLTSByPostCodeIndex,
   listArAgencyOrderInfos,
   queryArPriceInfosByQueryIDIndex,
   arEndUserByReceiptCode,
   queryMaterialStgLocsByItemCodeIndex,
   arCustomerAddressByOrderRecipientCode,
   listArAgencyOrderInfoByDetailsNumber,
   queryArItemMastersBySAPCODEIndex,
   queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex,
   queryArCustomerAddressesByCustomerReceiptCodeIndex,
   listArcalendars,
   queryArOrderRecipientItemSegGdcsByItemCodeIndex,
   queryMaterialStgLocsSalesDocType,
   listArSalesRepresentatives,
   customerByeMail
 } from "../graphql/queries";
 import API, { graphqlOperation } from "@aws-amplify/api";
 import {
   createArAgencyOrderInfo,
   updateArPriceInfo,
   createArPriceInfo,
   updateArAgencyOrderInfo,
 } from "../graphql/mutations";
 import { useHistory } from "react-router-dom";
 import DialogContentText from "@material-ui/core/DialogContentText";
 import { Auth } from "aws-amplify";
 import { Select, MenuItem, InputLabel, InputBase, TableHead } from "@material-ui/core";
 import { FormControl as FormControlsk } from "@material-ui/core";
 import { Box } from '@material-ui/core';
 import { FormHelperText } from '@material-ui/core';
 import userAuth from "../components/userAuth";
 import CircularProgress from '@material-ui/core/CircularProgress';
 const useStyles = makeStyles((theme) => ({
   root: {
     maxWidth: 200,
   },
   rootl: {
     maxWidth: "100%",
   },
   card1: {
     maxWidth: "50%",
     minWidth: "50%",
     width: "50%",
   },
   card2: {
     maxWidth: "100%",
   },
   table: {
     minWidth: "auto",
     display: "flex",
     justifyContent: "end",
     border: "none",
   },
   textlabel: {
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(1),
     width: "16ch",
     background: "transparent",
     borderBottom: "1px dashed #707070",
     border: "none",
     borderRadius: "1px",
     color: "black",
     fontWeight: "bold",
   },
   textlabelTT: {
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(1),
     width: "14ch",
     background: "transparent",
     borderBottom: "1px dashed #707070",
     border: "none",
     borderRadius: "1px",
     color: "black",
     fontWeight: "bold",
   },
   textField: {
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(1),
     width: "20ch",
     background: "transparent",
     borderBottom: "1px dashed #707070",
     border: "none",
     borderRadius: "1px",
     color: "black",
   },
   textFieldP: {
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(1),
     width: "100%",
     "text-align": "right",
     align: "right",
   },
   textFieldOrderQuantity: {
     align: "right",
   },
   textKField: {
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(1),
     background: "transparent",
     borderBottom: "1px dashed #707070",
     width: "auto",
     border: "none",
     borderRadius: "1px",
     color: "black",
   },
   media: {
     height: 0,
     paddingTop: "56.25%", // 16:9
   },
   expand: {
     transform: "rotate(0deg)",
     marginLeft: "auto",
     transition: theme.transitions.create("transform", {
       duration: theme.transitions.duration.shortest,
     }),
   },
   expandOpen: {
     transform: "rotate(180deg)",
   },
   avatar: {
     backgroundColor: red[500],
   },
   backButton: {
     marginRight: theme.spacing(1),
   },
   instructions: {
     marginTop: theme.spacing(1),
     marginBottom: theme.spacing(1),
   },
 }));
 const BootstrapInput = withStyles((theme) => ({
   root: {
     "label + &": {
       marginTop: "0px",
     },
   },
   input: {
     borderRadius: 4,
     position: "relative",
     backgroundColor: theme.palette.background.paper,
     border: "1px solid #ced4da",
     fontSize: 16,
     padding: "10px 26px 10px 12px",
     transition: theme.transitions.create(["border-color", "box-shadow"]),
     fontFamily: [
       "-apple-system",
       "BlinkMacSystemFont",
       '"Segoe UI"',
       "Roboto",
       '"Helvetica Neue"',
       "Arial",
       "sans-serif",
       '"Apple Color Emoji"',
       '"Segoe UI Emoji"',
       '"Segoe UI Symbol"',
     ].join(","),
     "&:focus": {
       borderRadius: 4,
       borderColor: "#80bdff",
       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
     },
   },
 }))(InputBase);
 const ITEM_HEIGHT = 100;
 const ITEM_PADDING_TOP = 9;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
       width: 400,
     },
   },
 };
 const QUERY_ZA = "QUERY_ZA";
 const QUERY_SH = "QUERY_SH";
 let invoiceSubtotal = 0;
 let invoiceFax = 0;
 let invoiceTotal = 0;
 let invoicePayTotal = 0;
 let OrderRecipientCodeId = "";
 let orderReadonly = false;
 let RepurchaseDisableInfo = false
 let RepurchaseDisable = true
 let hopeDateVal = ""
 let TAX_RATE = 0.1
 let User_Name = ""
 let AssigneeCode = ""
 let agencyId = ""
 let agencyEmail = ""
 let Agency_Name = ""
 let Company_Name = ""
 let subId = ""
 let useGroupClassification = ""
 let salesGroup = ""
 let usageSystem = ""
 const OrderEmd = () => {
   const location = useLocation();
   const history = useHistory();
   const classes = useStyles();
   const [QuoteNumber, setQuoteNumber] = useState();
   const [searchProgress, setSearchProgress] = useState(false);
   const handleSetOrderNoText = (Object, item) => {
     if (item) {
       item.OrderNumber = Object;
       setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
     }
   };
   const [OrderChargeNameText, setOrderChargeNameText] = React.useState("");
   const handleSetOrderChargeNameText = (val) => {
     setOrderChargeNameText(val);
   };
   const [addressList, setAddress] = useState([]);
   const handleSetOrderShipContactNmText = (Object, item) => {
     if (item) {
       item.OrderShipContactNm = Object;
     }
   };
   const handleSetOrderRemarksText = (Object, item) => {
     if (item) {
       item.OrderRemarks = Object;
     }
   };
   const handleShippingOrderNumberText = (Object, item) => {
     if (item) {
       item.ShippingOrderNumber = Object;
     }
   };
   const handlecontractorId = (Object) => {
     OrderRecipientCodeId = Object;
     handelEndUserChange(Object);
   };
   let formatYselect = "";
   let formatY = "";
   let todayTemp = new Date();
   let today = new Date(todayTemp.getTime() + 1000 * 60 * 60 * 72);
   formatY += today.getFullYear();
   formatY += today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1;
   formatY += today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
   formatYselect = formatY;
   const DateFunPick = moment(formatY).utcOffset(9).format('YYYY-MM-DD');
   const [IsWorkday, setIsWorkday] = useState([])
   const [selectedDate, setSelectedData] = React.useState(moment(formatYselect).utcOffset(9).format('YYYY-MM-DD'));
   const handelEndUserChange = async (OrderRecipientCodeId) => {
     const EndUseList = await API.graphql(
       graphqlOperation(arEndUserByReceiptCode, {
         RecipientCode: OrderRecipientCodeId,
       })
     );
     if (EndUseList.data.ArEndUsersByOrderRecipientCode !== null && EndUseList.data.ArEndUsersByOrderRecipientCode.items.length > 0) {
       let EndUserLIstInfo = EndUseList.data.ArEndUsersByOrderRecipientCode.items;
       EndUserLIstInfo = bubbleSort(EndUserLIstInfo,'Name1');
       setEndUsersOptions({
         type: QUERY_ZA,
         Invs: EndUserLIstInfo,
       });
     }
   };
   const handlecontractorNm = (Object, item) => {
     if (item) {
       item.contractorNm = Object;
     }
   };
   const getIsWorkday = async () => {
    let holidays = [];
    const listArcalendarsList = await API.graphql(
      graphqlOperation(listArcalendars, {
        filter: { IsWorkday: { eq: "0" } },
        limit: 10000,
      }))
    if (listArcalendarsList.data.listArcalendars !== null) {
      let nextToken = listArcalendarsList.data.listArcalendars.nextToken;
      holidays = listArcalendarsList.data.listArcalendars.items;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(listArcalendars, {
            filter: { IsWorkday: { eq: "0" } },
            limit: 10000,
            nextToken: nextToken,
          }))
        nextToken = InvDataSec.data.listArcalendars.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.listArcalendars.items.length;
          i++
        ) {
          holidays.push(InvDataSec.data.listArcalendars.items[i]);
        }
      }
    }
    return holidays;
  }
   const [flg, setInvoiceSubtotal] = React.useState(Object);
   const [msg1, setMsgId] = React.useState(Object);
   const handleSetMsgId = (Object) => {
     setMsgId(Object);
   };
   const [msgText, setMsgText] = React.useState(Object);
   const handleSetMsgText = (Object) => {
     setMsgText(Object);
   };
   const [msgbtnOK, setMsgBtnOK] = React.useState(Object);
   const handleSetMsgBtnOK = (Object) => {
     setMsgBtnOK(Object);
   };
   const [open2, setOpen2] = React.useState(false);
   const handleClickOpen2 = () => {
     setOpen2(true);
   };
   const [SalesUnitPriceScaleDisable, setSalesUnitPriceScaleDisable] = useState([])
   const handleCloseNG2 = () => {
     setOpen2(false);
   };
   const handleCloseOK2 = () => {
     setOpen2(false);
   };
   const [openmail, setOpenmail] = React.useState(false);
   const handleClickOpenmail = () => {
     setOpenmail(true);
   };
   const handleCloseNGmail = () => {
     setOpenmail(false);
   };
   const handleCloseOKmail = () => {
     setOpenmail(false);
   };
   const [msgmail, setMsgmail] = React.useState(Object);
   const handleSetMsgmail = (Object) => {
     setMsgmail(Object);
   };
   const [msgTextmail, setMsgTextmail] = React.useState(Object);
   const handleSetMsgTextmail = (Object) => {
     setMsgTextmail(Object);
   };
   const [open3, setOpen3] = React.useState(false);
   const handleClickOpen3 = () => {
     setOpen3(true);
   };
   const handleCloseNG3 = () => {
     setOpen3(false);
   };
   const handleCloseOK3 = async (item, order) => {
    setSearchProgress(true)
     if (order.length > 1) {
       handleSetMsgId("エラー");
       handleSetMsgText("複数商品選択はエラーになる");
       handleSetMsgBtnOK("はい");
       handleClickOpen2();
       setOpen3(false);
       setSearchProgress(false)
     } else {
       item.SalesUnitPriceScale = "0";
       item.UnitPriceAftDis = "0"
       item.endUserGroup = ""
       item.Contractor = "AA89104100"
       item.ContractorNm = "89104100(41)"
       item.SelectContractor = "AA89104100"
       item.SelectContractorNm = "89104100(41)"
       item.EndUserId = ""
       item.EndUserNm = ""
       item.SalesType = "サンプル"
       setContractorGroup("AA89104100" + ":" + "89104100(41)")
       setOpen3(false);
       PriceCalculation(orderinfo);
       let listAddressInfo = await API.graphql(
         graphqlOperation(arCustomerAddressByOrderRecipientCode, {
           OrderRecipientCode: item.SelectContractor,
         })
       );
       if (listAddressInfo.data.queryArCustomerAddressesByOrderRecipientCodeIndex !== null) {
         let listCustomerAddressList = listAddressInfo.data.queryArCustomerAddressesByOrderRecipientCodeIndex.items;
         listCustomerAddressList = bubbleSort(listCustomerAddressList,'SearchTerm1');
         setAddress(listCustomerAddressList);
         setShipingOptions({
           type: QUERY_SH,
           Invs: listCustomerAddressList,
         });
       }
       setSearchProgress(false)
     }
   };
   const signOut = async () => {
     await Auth.signOut();
     try {
       await Auth.signOut();
     } catch (error) {
       console.log("error signing out: ", error);
     }
     document.location.reload();
   };
   const [PriceRateTotal, setCampaignPriceTotal] = useState({
     CampaignPrice: 0, quantityPrice: 0,
   });
   const [orderinfoCopy, setOrderinfoCopy] = useState([]);
   const [orderinfo, setOrderinfo] = useState([]);
   useEffect(() => {
     async function listInvData() {
      setSearchProgress(true)
       RepurchaseDisableInfo = false
       RepurchaseDisable = true;
       orderReadonly = false;
       let OrderDetailsNo
       let QuoteNumberCopy
       let CalculationOk = false
       if (location.state) {
         if (location.state.CalculationOk) {
           CalculationOk = true
         }
         if (location.state.QuoteNumber) {
           QuoteNumberCopy = location.state.QuoteNumber
         }
         if (location.state.OrderDetailsNumber) {
           OrderDetailsNo = location.state.OrderDetailsNumber
         }
         if (location.state.readOnly) {
           orderReadonly = true;
         }
         if (location.state.RepurchaseDisable) {
           RepurchaseDisable = false
         }
       } else {
         let { QuoteNumber } = qs.parse(location.search.replace(/^\?/, ''))
         QuoteNumberCopy = QuoteNumber
       }
       let useAuths = await userAuth();
       useGroupClassification = useAuths
       if(useAuths !== "4" && useAuths !== "3" && useAuths !== "2"){
        orderReadonly = true;
        RepurchaseDisableInfo = true
       }
       let order = []
       if (OrderDetailsNo) {
         try {
           const listArAgencyOrderInfo = await API.graphql(graphqlOperation(listArAgencyOrderInfoByDetailsNumber, { OrderDetailsNumber: OrderDetailsNo }));
           if (listArAgencyOrderInfo.data.listArAgencyOrderInfoByDetailsNumber !== null) {
             order =
               listArAgencyOrderInfo.data.listArAgencyOrderInfoByDetailsNumber["items"];
           }
         } catch (error) {
           if (error.message === 'No current user') {
             // ユーザープールに障害が発生した場合
             console.log('User pool is invalid.Please reauthenticate.');
             // ログイン画面に戻る
             signOut();
           } else {
             // 他のエラーを処理する
             console.log('An error occurred:', error);
           }
         }
       } else {
         try {
           let listArAgencyOrderInfo = await API.graphql(graphqlOperation(listArAgencyOrderInfos, { QuoteNumber: QuoteNumberCopy }));
           if (listArAgencyOrderInfo.data.listArAgencyOrderInfos !== null) {
             order = listArAgencyOrderInfo.data.listArAgencyOrderInfos["items"];
           }
         } catch (error) {
           if (error.message === 'No current user') {
             // ユーザープールに障害が発生した場合
             console.log('User pool is invalid.Please reauthenticate.');
             // ログイン画面に戻る
             signOut();
           } else {
             // 他のエラーを処理する
             console.log('An error occurred:', error);
           }
         }
       }
       if (order.length > 0) {
         let addresslist = await getAddressList(order[0].SelectContractor);
         for (let i = 0; i < order.length - 1; i++) {
           for (let j = 0; j < order.length - 1 - i; j++) {
             if (order[j].OrderDetailsNumber > order[j + 1].OrderDetailsNumber) {
               let temp = order[j];
               order[j] = order[j + 1];
               order[j + 1] = temp;
             }
           }
         }
          const user = await Auth.currentUserInfo();
            const agencyPriceGroup = await API.graphql(
              graphqlOperation(customerByeMail, {
                Agency_Email: user.attributes.email,
              })
            );
            const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
            if (agentinfo.length > 0) {
             User_Name = agentinfo[0].User_Name;
             AssigneeCode = agentinfo[0].AssigneeCode;
             agencyId = agentinfo[0].Agency_id;
             agencyEmail = agentinfo[0].Agency_Email;
             Agency_Name = agentinfo[0].Agency_Name;
             Company_Name = agentinfo[0].Company_Name;
             subId = agentinfo[0].SubId;
             salesGroup = agentinfo[0].SalesGroup
             usageSystem = agentinfo[0].UsageSystem
            }
         let result = order.map(async (item,index) => {
          SalesUnitPriceScaleDisable.push({Disable:false})
           item["StgLocationlist"] = []
           item.DesiredDeliveryDate = moment(item.DesiredDeliveryDate).utcOffset(9).format("YYYY-MM-DD");
           item.DesiredDeliveryDateCopy = item.DesiredDeliveryDate
           item.ShippingData = item.ShippingDestination ? item.ShippingDestination + ":" + item.ShippingDestinationNm : "";
           item.endUserGroup = (item.SelectContractor && (item.SelectContractor === "AA89104100" || item.SelectContractor === "AA89108100" || item.SelectContractor === "AA89106200")) ? "" : (item.EndUserId ? item.EndUserId + ":" + item.EndUserNm : "");
           item.SalesType = (item.SelectContractor && (item.SelectContractor === "AA89104100" || item.SelectContractor === "AA89108100" || item.SelectContractor === "AA89106200")) ? "サンプル" : "通常購入"
           let SegmentList = await API.graphql(graphqlOperation(queryArOrderRecipientItemSegGdcsByItemCodeIndex, { ItemCode: item.Product_Code }))
           if (SegmentList.data.queryArOrderRecipientItemSegGdcsByItemCodeIndex !== null && SegmentList.data.queryArOrderRecipientItemSegGdcsByItemCodeIndex["items"].length > 0) {
             item.Segment = SegmentList.data.queryArOrderRecipientItemSegGdcsByItemCodeIndex["items"][0].Segment
           }
             //保管場所の取得
             if(item.Storage_Location_U){
              let StgLocationInit = await API.graphql(graphqlOperation(queryArItemMastersBySAPCODEIndex, { SAPCODE: item.Product_Code,filter:{ARBSuppAgencyCode:{eq: useGroupClassification},DelFlag: {ne: "1"}} }));
              if (StgLocationInit.data.queryArItemMastersBySAPCODEIndex !== null && StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"].length > 0) {
                item.QuotationReplyLT = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].QuotationReplyLT
                item.BaseNumUnit = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].BaseNumUnit
                item.ItemGroup = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].ItemGrp
              if(!orderReadonly){
                //DT在庫区分の取得
                item.DTStockTier = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].InventoryTier
                //DT通常LTの取得
                item.DTNormalLT = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT
                if (item.Sales_Document_Type === "ZT11") {
                  if (!isNaN(parseInt(StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT))) {
                    let DropDeliveryTime = await addDays((moment(new Date()).utcOffset(9).format('YYYY/MM/DD')), parseInt(StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT))
                    item.DropDeliveryTime = moment(DropDeliveryTime).utcOffset(9).format('YYYY/MM/DD')
                  } else {
                    item.DropDeliveryTime = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT
                  }
                }
              }
             }
             }else{
             let StgLocationInit = await API.graphql(graphqlOperation(queryArItemMastersBySAPCODEIndex, { SAPCODE: item.Product_Code,filter:{ARBSuppAgencyCode:{eq: useGroupClassification},DelFlag: {ne: "1"}} }));
             if (StgLocationInit.data.queryArItemMastersBySAPCODEIndex !== null && StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"].length > 0) {
              if(CalculationOk || (!item.Storage_Location_U)){
                item.Storage_Location_U = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].StorageLoc
               }
               item.QuotationReplyLT = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].QuotationReplyLT
               item.BaseNumUnit = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].BaseNumUnit
               item.ItemGroup = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].ItemGrp
             if(!orderReadonly){
               //DT在庫区分の取得
               item.DTStockTier = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].InventoryTier
               //DT通常LTの取得
               item.DTNormalLT = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT
               if (item.Sales_Document_Type === "ZT11") {
                 if (!isNaN(parseInt(StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT))) {
                   let DropDeliveryTime = await addDays((moment(new Date()).utcOffset(9).format('YYYY/MM/DD')), parseInt(StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT))
                   item.DropDeliveryTime = moment(DropDeliveryTime).utcOffset(9).format('YYYY/MM/DD')
                 } else {
                   item.DropDeliveryTime = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT
                 }
               }
             }
            }
          }
            if(!orderReadonly){
              //規定数量の表示、小分け
              let RegQtyAndBulkSales = await getRegQtyAndBulkSales(item.Product_id)
              item.RegQty = RegQtyAndBulkSales.RegQty
              item.BulkSales = RegQtyAndBulkSales.BulkSales
              item.OrdQty = RegQtyAndBulkSales.OrdQty
              //出荷先とエンドユーザー初期値の設
              if(CalculationOk){
                handleChange1(item.ShippingData, item, addresslist["listCustomerAddressList"], order,index)
              }else{
                    if(item.ShippingDestination){
                      //最短参考納期
                      if (item.Sales_Document_Type === "ZT31" || item.Sales_Document_Type === "ZS01") {
                        let date = new Date()
                        if (date.getHours() >= 0 && date.getHours() < 12) {
                          let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (2 + parseInt(item.FukuiSeinoLT ? item.FukuiSeinoLT : 0)))
                          item.ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
                        } else if (date.getHours() >= 12 && date.getHours() < 24) {
                          let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (3 + parseInt(item.FukuiSeinoLT ? item.FukuiSeinoLT : 0)))
                          item.ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
                        }
                      }
                    }
                    PriceCalculation(order)
                   }
            }
           item.SalesGroupInfo = salesGroup;
           let StgLocationlist = []
           let Plant = ""
          let StgLocationInfo = await API.graphql(graphqlOperation(queryMaterialStgLocsByItemCodeIndex, { ItemCode: item.Product_Code, UsageCategory:useGroupClassification}))
           if (StgLocationInfo.data.queryMaterialStgLocsByItemCodeIndex !== null && StgLocationInfo.data.queryMaterialStgLocsByItemCodeIndex["items"].length > 0) {
                StgLocationInfo.data.queryMaterialStgLocsByItemCodeIndex["items"].forEach((ele) => {
                StgLocationlist.push(ele.StorageLoc)
                if(ele.StorageLoc === item.Storage_Location_U){
                  Plant = ele.Plant
                }
                });
                  let findOrder = StgLocationInfo.data.queryMaterialStgLocsByItemCodeIndex["items"].findIndex(function (ele) {
                  return ele.StorageLoc === item.Storage_Location_U
                  })
                if(findOrder < 0){
                  StgLocationlist.push(item.Storage_Location_U)
                  }
           item.Plant = Plant
           item["StgLocationlist"] = Array.from(new Set(StgLocationlist))
         }
        })
         let res = await Promise.all(result)
         if (order.length > 0) {
          if(order[0].SalesGroup !== salesGroup){
            RepurchaseDisableInfo = true
            orderReadonly = true
          }
           hopeDateVal = order[0].DesiredDeliveryDate
           setContractorGroup(order[0].SelectContractor + ":" + order[0].SelectContractorNm)
           for(let i = 0;i < order.length;i++){
            if(order[i].OrderChargeName !== ""){
              setOrderChargeNameText(order[i].OrderChargeName)
            }
           }
         }
         if(orderReadonly || (!CalculationOk)){
          invoiceFax = 0
          invoicePayTotal = 0
          invoiceSubtotal = 0
          invoiceTotal = 0
          for(let i = 0;i < order.length;i++){
            invoiceFax += Number(moneytonum(order[i].invoiceFax));
          invoicePayTotal += Number(moneytonum(order[i].invoicePayTotal))
          invoiceSubtotal += Number(moneytonum(order[i].invoiceSubtotal))
          invoiceTotal += Number(moneytonum(order[i].invoiceTotal))
          }
          invoicePayTotal = numtomoney(Math.round(invoicePayTotal))
          invoiceSubtotal = numtomoney(Math.round(invoiceSubtotal))
          invoiceFax = numtomoney(Math.round(invoiceFax))
          invoiceTotal = numtomoney(Math.round(invoiceTotal))
         }
         let IsWorkdays = await getIsWorkday()
         setIsWorkday(IsWorkdays)
         setOrderinfo(order);
         setOrderinfoCopy(JSON.parse(JSON.stringify(order)))
       }
       setQuoteNumber(QuoteNumberCopy)
       setSearchProgress(false)
     }
     listInvData();
   }, []);
      //仕入単価計算
      const PurchpriceInfoRE = async (item, arr,index) => {
        let Purchprice = ""
        let PurchUnitPriceObj = {}
        let UnitPriceArr = []
        let ExpiredArr = []
        //let resultArr = []
        for (let ele = 0; ele < arr.length; ele++) {
          if (arr[ele].Expired) {
            if (arr[ele].AcctForPurchasesApplicableble === "出荷日") {
              let leadTime = 0
              if (arr[ele].ShipmentCode) {
                let listCustomerAddressesInfo = await API.graphql(
                  graphqlOperation(queryArCustomerAddressesByCustomerReceiptCodeIndex, {
                    CustomerReceiptCode: arr[ele].ShipmentCode,
                  })
                );
                if (listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex !== null) {
                  let CustomerAddressesInfo = listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex.items;
                  //福井西濃LT
                  if (CustomerAddressesInfo.length > 0 && CustomerAddressesInfo[0].PostCode) {
                    leadTime = await FukuiSeinoLTFun(CustomerAddressesInfo[0].PostCode)
                  }
                }
              }
              let shipDate = await reduceDays((moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
              if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) >= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
                ExpiredArr.push(arr[ele])
                // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
                //   resultArr.push(arr[ele])
                // }
              }
            } else if (arr[ele].AcctForPurchasesApplicableble === "納品日") {
              if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
                ExpiredArr.push(arr[ele])
                // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
                //   resultArr.push(arr[ele])
                // }
              }
            } else if (arr[ele].AcctForPurchasesApplicableble === "受注日") {
              if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD') >= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
                ExpiredArr.push(arr[ele])
                // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) && (moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
                //   resultArr.push(arr[ele])
                // }
              }
            }
          } else {
            if (arr[ele].AcctForPurchasesApplicableble === "出荷日") {
              let leadTime = 0
              if (arr[ele].ShipmentCode) {
                let listCustomerAddressesInfo = await API.graphql(
                  graphqlOperation(queryArCustomerAddressesByCustomerReceiptCodeIndex, {
                    CustomerReceiptCode: arr[ele].ShipmentCode,
                  })
                );
                if (listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex !== null) {
                  let CustomerAddressesInfo = listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex.items;
                  //福井西濃LT
                  if (CustomerAddressesInfo.length > 0 && CustomerAddressesInfo[0].PostCode) {
                    leadTime = await FukuiSeinoLTFun(CustomerAddressesInfo[0].PostCode)
                  }
                }
              }
              let shipDate = await reduceDays((moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
              if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) { UnitPriceArr.push(arr[ele]) }
            } else if (arr[ele].AcctForPurchasesApplicableble === "納品日") {
              if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD'))) {
                UnitPriceArr.push(arr[ele])
              }
            } else if (arr[ele].AcctForPurchasesApplicableble === "受注日") {
              Date
              if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
                UnitPriceArr.push(arr[ele])
              }
            }
          }
        }
        // if(ExpiredArr.length > 1){
        //   UnitPriceArr.push(...resultArr)
        //  }else{
          UnitPriceArr.push(...ExpiredArr)
        //  }
        let arr1 = await UnitPriceScaleFun(UnitPriceArr,item,index,false)
        let obj = {}
        if (arr1.length > 0) {
         //卸売価格
         if(arr1.length > 1){
           let arr2 = arr1.filter((a)=>{
             if((a.RegQty).includes("<=")){
               if(Number(item.OrderQuantity) >= parseInt(a.RegQty)){
                 return true
               }}})
           let arr3 = arr1.filter((a)=>{
             if(!((a.RegQty).includes("<="))){
             return true}})
           if(arr2.length > 0){
             if (arr2.length > 1) {
                   if (item.BulkSales === "0") {
                     let arr = []
                     for(let i = 0;i < arr2.length;i++){
                       switch (String(item.OrderQuantity)) {
                         case arr2[i].PurchUnitPriceUnitScale1: {
                           arr.push({PurchUnitPriceObj:arr2[i],price:arr2[i].PurchUnitPriceScale1});break;
                         }
                         case arr2[i].PurchUnitPriceUnitScale2: {
                           arr.push({PurchUnitPriceObj:arr2[i],price:arr2[i].PurchUnitPriceScale2 || arr2[i].PurchUnitPriceScale1}); break;
                         }
                         case arr2[i].PurchUnitPriceUnitScale3: {
                           arr.push({PurchUnitPriceObj:arr2[i],price:arr2[i].PurchUnitPriceScale3 || arr2[i].PurchUnitPriceScale3 || arr2[i].PurchUnitPriceScale1}); break;
                         }
                         case arr2[i].PurchUnitPriceUnitScale4: {
                           arr.push({PurchUnitPriceObj:arr2[i],price:arr2[i].PurchUnitPriceScale4 || arr2[i].PurchUnitPriceScale3 || arr2[i].PurchUnitPriceScale2 || arr2[i].PurchUnitPriceScale1}); break;
                         }
                         case arr2[i].PurchUnitPriceUnitScale5: {
                           arr.push({PurchUnitPriceObj:arr2[i],price:arr2[i].PurchUnitPriceScale5 || arr2[i].PurchUnitPriceScale4 || arr2[i].PurchUnitPriceScale3 || arr2[i].PurchUnitPriceScale2 || arr2[i].PurchUnitPriceScale1}); break;
                         }
                         default: {
                           arr.push({PurchUnitPriceObj:arr2[i],price:calculatePrice(Number(item.OrderQuantity),Number(arr2[i].PurchUnitPriceUnitScale1),Number(arr2[i].PurchUnitPriceUnitScale2),Number(arr2[i].PurchUnitPriceUnitScale3),Number(arr2[i].PurchUnitPriceUnitScale4),Number(arr2[i].PurchUnitPriceUnitScale5),arr2[i].PurchUnitPriceScale1,arr2[i].PurchUnitPriceScale2,arr2[i].PurchUnitPriceScale3,arr2[i].PurchUnitPriceScale4,arr2[i].PurchUnitPriceScale5)}); break;
                         }
                       }
                     }
                     arr = [...arr].sort(function(a, b) {return a.price - b.price})
                     Purchprice = arr[0].price
                     PurchUnitPriceObj = arr[0].PurchUnitPriceObj
                   } else {
                     let arr = []
                     for(let i = 0;i < arr2.length;i++){
                       arr.push({PurchUnitPriceObj:arr2[i],price:calculatePrice(Number(item.OrderQuantity),Number(arr2[i].PurchUnitPriceUnitScale1),Number(arr2[i].PurchUnitPriceUnitScale2),Number(arr2[i].PurchUnitPriceUnitScale3),Number(arr2[i].PurchUnitPriceUnitScale4),Number(arr2[i].PurchUnitPriceUnitScale5),arr2[i].PurchUnitPriceScale1,arr2[i].PurchUnitPriceScale2,arr2[i].PurchUnitPriceScale3,arr2[i].PurchUnitPriceScale4,arr2[i].PurchUnitPriceScale5)})
                     }
                     arr = [...arr].sort(function(a, b) {return a.price - b.price})
                     Purchprice = arr[0].price
                     PurchUnitPriceObj = arr[0].PurchUnitPriceObj
                   }
             } else {
               obj = arr2[0]
               PurchUnitPriceObj = arr2[0]
               if (item.BulkSales === "0") {
                 switch (String(item.OrderQuantity)) {
                   case obj.PurchUnitPriceUnitScale1: Purchprice = obj.PurchUnitPriceScale1; break;
                   case obj.PurchUnitPriceUnitScale2: Purchprice = obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                   case obj.PurchUnitPriceUnitScale3: Purchprice = obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                   case obj.PurchUnitPriceUnitScale4: Purchprice = obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                   case obj.PurchUnitPriceUnitScale5: Purchprice = obj.PurchUnitPriceScale5 || obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                   default: Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5); break;
                 }
               } else {
                Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5)
               }
             }
           }else{
             if (arr3.length > 1) {
               if (item.BulkSales === "0") {
                 let arr = []
                     for(let i = 0;i < arr3.length;i++){
                       switch (String(item.OrderQuantity)) {
                         case arr3[i].PurchUnitPriceUnitScale1: {
                           arr.push({PurchUnitPriceObj:arr3[i],price:arr3[i].PurchUnitPriceScale1});break;
                         }
                         case arr3[i].PurchUnitPriceUnitScale2: {
                           arr.push({PurchUnitPriceObj:arr3[i],price:arr3[i].PurchUnitPriceScale2 || arr3[i].PurchUnitPriceScale1}); break;
                         }
                         case arr3[i].PurchUnitPriceUnitScale3: {
                           arr.push({PurchUnitPriceObj:arr3[i],price:arr3[i].PurchUnitPriceScale3 || arr3[i].PurchUnitPriceScale3 || arr3[i].PurchUnitPriceScale1}); break;
                         }
                         case arr3[i].PurchUnitPriceUnitScale4: {
                           arr.push({PurchUnitPriceObj:arr3[i],price:arr3[i].PurchUnitPriceScale4 || arr3[i].PurchUnitPriceScale3 || arr3[i].PurchUnitPriceScale2 || arr3[i].PurchUnitPriceScale1}); break;
                         }
                         case arr3[i].PurchUnitPriceUnitScale5: {
                           arr.push({PurchUnitPriceObj:arr3[i],price:arr3[i].PurchUnitPriceScale5 || arr3[i].PurchUnitPriceScale4 || arr3[i].PurchUnitPriceScale3 || arr3[i].PurchUnitPriceScale2 || arr3[i].PurchUnitPriceScale1}); break;
                         }
                         default: {
                           arr.push({PurchUnitPriceObj:arr3[i],price:calculatePrice(Number(item.OrderQuantity),Number(arr3[i].PurchUnitPriceUnitScale1),Number(arr3[i].PurchUnitPriceUnitScale2),Number(arr3[i].PurchUnitPriceUnitScale3),Number(arr3[i].PurchUnitPriceUnitScale4),Number(arr3[i].PurchUnitPriceUnitScale5),arr3[i].PurchUnitPriceScale1,arr3[i].PurchUnitPriceScale2,arr3[i].PurchUnitPriceScale3,arr3[i].PurchUnitPriceScale4,arr3[i].PurchUnitPriceScale5)}); break;
                         }
                       }
                     }
                     arr = [...arr].sort(function(a, b) {return a.price - b.price})
                     Purchprice = arr[0].price
                     PurchUnitPriceObj = arr[0].PurchUnitPriceObj
           } else {
             let arr = []
             for(let i = 0;i < arr3.length;i++){
               arr.push({PurchUnitPriceObj:arr3[i],price:calculatePrice(Number(item.OrderQuantity),Number(arr3[i].PurchUnitPriceUnitScale1),Number(arr3[i].PurchUnitPriceUnitScale2),Number(arr3[i].PurchUnitPriceUnitScale3),Number(arr3[i].PurchUnitPriceUnitScale4),Number(arr3[i].PurchUnitPriceUnitScale5),arr3[i].PurchUnitPriceScale1,arr3[i].PurchUnitPriceScale2,arr3[i].PurchUnitPriceScale3,arr3[i].PurchUnitPriceScale4,arr3[i].PurchUnitPriceScale5)})
             }
             arr = [...arr].sort(function(a, b) {return a.price - b.price})
             Purchprice = arr[0].price
             PurchUnitPriceObj = arr[0].PurchUnitPriceObj
           }
             } else {
                 obj = arr3.length > 0 && arr3[0]
                 PurchUnitPriceObj = arr3.length > 0 && arr3[0]
                 if (item.BulkSales === "0") {
                   switch (String(item.OrderQuantity)) {
                     case obj.PurchUnitPriceUnitScale1: Purchprice = obj.PurchUnitPriceScale1; break;
                     case obj.PurchUnitPriceUnitScale2: Purchprice = obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                     case obj.PurchUnitPriceUnitScale3: Purchprice = obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                     case obj.PurchUnitPriceUnitScale4: Purchprice = obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                     case obj.PurchUnitPriceUnitScale5: Purchprice = obj.PurchUnitPriceScale5 || obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                     default: Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5); break;
                   }
                 } else {
                   Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5)
                  }
             }
           }
         }else{
           obj = arr1[0]
           PurchUnitPriceObj = arr1[0]
           if (item.BulkSales === "0") {
             switch (String(item.OrderQuantity)) {
               case obj.PurchUnitPriceUnitScale1: Purchprice = obj.PurchUnitPriceScale1; break;
               case obj.PurchUnitPriceUnitScale2: Purchprice = obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
               case obj.PurchUnitPriceUnitScale3: Purchprice = obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
               case obj.PurchUnitPriceUnitScale4: Purchprice = obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
               case obj.PurchUnitPriceUnitScale5: Purchprice = obj.PurchUnitPriceScale5 || obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
               default: Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5); break;
             }
           } else {
             Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5)
            }
         }
        }
        return {PurchUnitPriceObj:PurchUnitPriceObj,price:isValidNumber(moneytonum(Purchprice)) === false ? "" : Purchprice}
      }
   const handStorageLoc = async (e, item, order,index) => {
    setSearchProgress(true)
     let StorageLocVal = e.target.value
     let suppNameObj = {}
     try {
       let StgLocationlist = null
       let StgLocationInfo = await API.graphql(graphqlOperation(queryMaterialStgLocsSalesDocType, { ItemCode: item.Product_Code, filter: { StorageLoc: { eq: StorageLocVal }, UsageCategory: { eq: useGroupClassification } } }));
       if (StgLocationInfo.data.queryMaterialStgLocsSalesDocType !== null) {
         StgLocationlist = StgLocationInfo.data.queryMaterialStgLocsSalesDocType["items"]
       }
       if (StgLocationlist.length > 0 && StgLocationlist.length > 0) {
         item.Sales_Document_Type = StgLocationlist[0].SalesDocType
         item.Plant = StgLocationlist[0].Plant
       }
     } catch (error) {
       if (error.message === 'No current user') {
         // ユーザープールに障害が発生した場合
         console.log('User pool is invalid.Please reauthenticate.');
         // ログイン画面に戻る
         signOut();
       } else {
         // 他のエラーを処理する
         console.log('An error occurred:', error);
       }
     }
     let StgLocationInit = await API.graphql(graphqlOperation(queryArItemMastersBySAPCODEIndex, { SAPCODE: item.Product_Code, filter: {ARBSuppAgencyCode:{eq: useGroupClassification},DelFlag: {ne: "1"} } }));
     if (StgLocationInit.data.queryArItemMastersBySAPCODEIndex !== null && StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"].length > 0) {
       //DT在庫区分の取得
       item.DTStockTier = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].InventoryTier
       item.QuotationReplyLT = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].QuotationReplyLT
       item.ItemGroup = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].ItemGrp
       item.BaseNumUnit = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].BaseNumUnit
       //DT通常LTの取得
       item.DTNormalLT = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT
     } else {
       e.target.value = item.Storage_Location_U
     }
     if (item.Sales_Document_Type === "ZT31" || item.Sales_Document_Type === "ZS01") {
      if(item.ShippingDestination){
        let date = new Date()
        if (date.getHours() >= 0 && date.getHours() < 12) {
          let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (2 + parseInt(item.FukuiSeinoLT ? item.FukuiSeinoLT : 0)))
          item.ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
        } else if (date.getHours() >= 12 && date.getHours() < 24) {
          let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (3 + parseInt(item.FukuiSeinoLT ? item.FukuiSeinoLT : 0)))
          item.ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
        }
      }
       item.DropDeliveryTime = ""
     } else if (item.Sales_Document_Type === "ZT11") {
       if (StgLocationInit.data.queryArItemMastersBySAPCODEIndex !== null && StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"].length > 0) {
         if (!isNaN(parseInt(StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT))) {
           let DropDeliveryTime = await addDays((moment(new Date()).utcOffset(9).format('YYYY/MM/DD')), parseInt(StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT))
           item.DropDeliveryTime = moment(DropDeliveryTime).utcOffset(9).format('YYYY/MM/DD')
         } else {
           item.DropDeliveryTime = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT
         }
       } else {
         item.DropDeliveryTime = ""
       }
       item.ShortestRefTime = ""
     }
     item.Storage_Location_U = e.target.value
     if (item.SalesType !== "サンプル") {
      let arr = await SalesUnitPriceScaleFun(item)
      let SalesUnitPriceObj = await SalespriceInfo(item, arr,index)
      item.BulkShippingFee = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.BulkShippingFee
      item.CoolDeliveryCharge = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.CoolDeliveryCharge
      item.SalesUnitPriceScale = SalesUnitPriceObj.price
      item.PurchUnitPriceScale = await PurchpriceInfo(item, arr,index)
      suppNameObj = SalesUnitPriceObj.SalesUnitPriceObj
    } else {
      let arr = await SalesUnitPriceScaleFun(item)
      let PurchUnitPriceObj = await PurchpriceInfoRE(item, arr,index)
      item.PurchUnitPriceScale = PurchUnitPriceObj.price
      item.SalesUnitPriceScale = 0
      suppNameObj = PurchUnitPriceObj.PurchUnitPriceObj
    }
    if(e.target.value !== "A002"){
      item.SuppName = ""
      item.Supplier = ""
      item.TorayShipCode = null
     }else{
      item.SuppName = Object.keys(suppNameObj).length > 0 && suppNameObj.SuppName
      item.Supplier = Object.keys(suppNameObj).length > 0 && suppNameObj.SuppCode
      for (let j = 0; j < addressList.length; j++) {
        if (addressList[j].CustomerReceiptCode === item.ShippingDestination) {
        if(item.SuppName === "ダウ・東レ_特価" || item.SuppName === "ダウ・東レ_BOOK"){
          item.TorayShipCode = addressList[j].TorayShipCode
        }}
      }
     }
     PriceCalculation(order)
     setSearchProgress(false)
   }
   const disableWeekends = (date)=> {
    return IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === date.getTime())
}
   const IsWorkdayFun = (date)=>{
    // const date1 = new Date(date);
    // const date2 = new Date();
    // date1.setHours(0, 0, 0, 0);
    // date2.setHours(0, 0, 0, 0);
    // const diffTime = Math.abs(date1 - date2);
    // const daysDifference = Math.ceil(diffTime / (1000 * 3600 * 24));
    // let day = ""
    // if (daysDifference === 0){
    //     let days = 3;
    //     let tempDate = new Date(date);
    // for (let i = 0; i < days; i++) {
    //   tempDate.setDate(tempDate.getDate() + 1);
    //   if (IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
    //     tempDate.setDate(tempDate.getDate() + 1);
    //     i--
    //   }
    // }
    // day = tempDate
    // }else if(daysDifference === 1){
    //   let days = 2;
    //     let tempDate = new Date(date);
    // for (let i = 0; i < days; i++) {
    //   tempDate.setDate(tempDate.getDate() + 1);
    //   if (IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
    //     tempDate.setDate(tempDate.getDate() + 1);
    //     i--
    //   }
    // }
    // day = tempDate
    // }else if(daysDifference === 2){
    //   let days = 1;
    //     let tempDate = new Date(date);
    // for (let i = 0; i < days; i++) {
    //   tempDate.setDate(tempDate.getDate() + 1);
    //   if (IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
    //     tempDate.setDate(tempDate.getDate() + 1);
    //     i--
    //   }
    // }
    // day = tempDate
    // }else{
    //   day = date
    // }
    return (moment(date).utcOffset(9).format('YYYY-MM-DD'))
  }
   const handleSelectedDate = async (e, item, orderinfo,index) => {
    setSearchProgress(true)
    let day = new Date(IsWorkdayFun(e))
     if (moment(e).utcOffset(9).format('YYYY/MM/DD') < moment(new Date).utcOffset(9).format('YYYY/MM/DD')) {
       item.DesiredDeliveryDateCopy = item.DesiredDeliveryDate
     } else {
       item.DesiredDeliveryDateCopy = day
     }
     item.DesiredDeliveryDate = day;
     hopeDateVal = day
     if(item.ShippingDestination){
      if (item.Sales_Document_Type === "ZT31" || item.Sales_Document_Type === "ZS01") {
        let date = new Date()
        try {
          if (date.getHours() >= 0 && date.getHours() < 12) {
            let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (2 + parseInt(item.FukuiSeinoLT ? item.FukuiSeinoLT : 0)))
            item.ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
          } else if (date.getHours() >= 12 && date.getHours() < 24) {
            let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (3 + parseInt(item.FukuiSeinoLT ? item.FukuiSeinoLT : 0)))
            item.ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
          }
        } catch (error) {
          if (error.message === 'No current user') {
            // ユーザープールに障害が発生した場合
            console.log('User pool is invalid.Please reauthenticate.');
            // ログイン画面に戻る
            signOut();
          } else {
            // 他のエラーを処理する
            console.log('An error occurred:', error);
          }
        }
      }
     }
     if (item.SalesType !== "サンプル") {
       let arr = await SalesUnitPriceScaleFun(item)
       let SalesUnitPriceObj = await SalespriceInfo(item, arr,index)
       item.BulkShippingFee = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.BulkShippingFee
       item.CoolDeliveryCharge = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.CoolDeliveryCharge
       item.SalesUnitPriceScale = SalesUnitPriceObj.price
       item.PurchUnitPriceScale = await PurchpriceInfo(item, arr,index)
      } else {
       let arr = await SalesUnitPriceScaleFun(item)
       item.PurchUnitPriceScale = await PurchpriceInfo(item, arr,index)
       item.SalesUnitPriceScale = 0
     }
     PriceCalculation(orderinfo)
     setSelectedData(day)
     setSearchProgress(false)
   }
   const handleUnitPriceAftDisChange = (e, item) => {
     let reg = /^(0|[1-9][0-9]*)$/
     e.target.value = e.target.value.replace(/[^0-9]/g, '')
     if (e.target.value !== "" && !reg.test(e.target.value)) {
       handleSetMsgId("エラー");
       handleSetMsgText("数字のみ入力可能");
       handleSetMsgBtnOK("はい");
       handleClickOpen2();
       e.target.value = item.UnitPriceAftDis
     }
     item.UnitPriceAftDis = e.target.value
     try {
       PriceCalculation(orderinfo)
     } catch (error) {
       if (error.message === 'No current user') {
         // ユーザープールに障害が発生した場合
         console.log('User pool is invalid.Please reauthenticate.');
         // ログイン画面に戻る
         signOut();
       } else {
         // 他のエラーを処理する
         console.log('An error occurred:', error);
       }
     }
   }
   const handleOrderQuantity = (e, item) => {
     item.OrderQuantity = e.target.value;
     setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
   }
   const handleBlurOrderQuantity = async (e, item, index) => {
    setSearchProgress(true)
    try {
    item.OrderQuantity = ""
    if (!e.target.value || e.target.value === null || e.target.value === undefined || e.target.value === "0") {
      handleSetMsgId("エラー");
      handleSetMsgText("数量が未入力です");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      setSearchProgress(false)
      setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
      return;
    }
    let reg = /^([1-9][0-9]*)$/
    if (!reg.test(e.target.value)) {
      handleSetMsgId("エラー");
      handleSetMsgText("数量欄に入力できるのは整数のみ");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      setSearchProgress(false)
      setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
      return;
    }
    if (item.SelectContractor !== "AA89104100" && item.SelectContractor !== "AA89108100" && item.SelectContractor !== "AA89106200" && item.SalesType !== "サンプル") {
     if (item.BulkSales === "0") {
      if (e.target.value !== 0 && item.RegQty && (Number(e.target.value) % parseInt(item.RegQty)) !== 0) {
        handleSetMsgId("エラー");
        handleSetMsgText("規定数量倍数以外は入力不可");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        setSearchProgress(false)
        setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
        return;
      }
      if ((item.RegQty).includes(">=")) {
        if (item.RegQty && e.target.value !== 0 && (Number(e.target.value) > parseInt(item.RegQty))) {
          handleSetMsgId("エラー");
          handleSetMsgText("規定数量下限値以上の値を設定してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setSearchProgress(false)
          setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
          return;
        }
      }
      if ((item.RegQty).includes("<=")) {
        if (item.RegQty && e.target.value !== 0 && (Number(e.target.value) < parseInt(item.RegQty))) {
          handleSetMsgId("エラー");
          handleSetMsgText("規定数量下限値以上の値を設定してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setSearchProgress(false)
          setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
          return;
        }
      }
    } else {
      if ((item.RegQty).includes(">=")) {
        if (item.RegQty && e.target.value !== 0 && (Number(e.target.value) > parseInt(item.RegQty))) {
          handleSetMsgId("エラー");
          handleSetMsgText("規定数量下限値以上の値を設定してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setSearchProgress(false)
          setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
          return;
        }
      }
      if ((item.RegQty).includes("<=")) {
        if (item.RegQty && e.target.value !== 0 && (Number(e.target.value) < parseInt(item.RegQty))) {
          handleSetMsgId("エラー");
          handleSetMsgText("規定数量下限値以上の値を設定してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setSearchProgress(false)
          setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
          return;
        }
      }
    }
  }
  if(item.OrdQty && item.OrderQuantity){
    let OrdQty = isNaN(parseInt(item.OrdQty)) ? 0 : parseInt(item.OrdQty)
    if(OrdQty !== 0 && (Number(item.OrderQuantity) > OrdQty)){
    handleSetMsgId("エラー");
    handleSetMsgText(`1注文可能数は${OrdQty}です。1注文可能数以下で入力してください`);
    handleSetMsgBtnOK("はい");
    handleClickOpen2();
    setSearchProgress(false)
    setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
    return false;
    }
}
     item.OrderQuantity = e.target.value
     if (item.SalesType !== "サンプル") {
       let arr = await SalesUnitPriceScaleFun(item)
       let SalesUnitPriceObj = await SalespriceInfo(item, arr,index)
       item.BulkShippingFee = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.BulkShippingFee
       item.CoolDeliveryCharge = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.CoolDeliveryCharge
       item.SalesUnitPriceScale = SalesUnitPriceObj.price
       item.PurchUnitPriceScale = await PurchpriceInfo(item, arr,index)
     } else {
       let arr = await SalesUnitPriceScaleFun(item)
       item.PurchUnitPriceScale = await PurchpriceInfo(item, arr,index)
       item.SalesUnitPriceScale = 0
     }
     PriceCalculation(orderinfo)
    } catch (error) {
      if (error.message === 'No current user') {
        // ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        // ログイン画面に戻る
        signOut();
      } else {
        // 他のエラーを処理する
        console.log('An error occurred:', error);
      }
    }
     setSearchProgress(false)
   }
   const initialcontractor = { Invs: [] };
   const handleContractorChange = (event) => {
     let Contractor_temp = event.target.value;
     let ContractorID = Contractor_temp.split(":");
     handlecontractorId(ContractorID[0]);
     handlecontractorNm(ContractorID[1]);
   };
   const handleEndUserChange = async (value, item,index) => {
    setSearchProgress(true)
     let val = value
     if (value) {
       let Alldata = [];
       let Alldata2 = [];
       const InvData = await API.graphql(
         graphqlOperation(queryArPriceInfosByQueryIDIndex3, {
           QueryId: "P",
           limit: 10000,
           filter: {
             ItemCode: { eq: item.Product_Code },
             OrderReceiptCode: { eq: item.SelectContractor },
             EUARBmgmtCode: { eq: item.EndUserId },
             ActualFlag:{eq:"0"},
             UseGroupClassification:{eq: useGroupClassification}
           }
         })
       );
       if (InvData.data.queryArPriceInfosByQueryIDIndex !== null) {
         let nextToken = InvData.data.queryArPriceInfosByQueryIDIndex.nextToken;
         Alldata = InvData.data.queryArPriceInfosByQueryIDIndex.items;
         while (nextToken !== null) {
           let InvDataSec = await API.graphql(
             graphqlOperation(queryArPriceInfosByQueryIDIndex3, {
               QueryId: "P",
               limit: 10000,
               nextToken: nextToken,
               filter: {
                 ItemCode: { eq: item.Product_Code },
                 OrderReceiptCode: { eq: item.SelectContractor },
                 EUARBmgmtCode: { eq: val.split(":")[0] },
                 ActualFlag:{eq:"0"},
                 UseGroupClassification:{eq: useGroupClassification}
               }
             }
             ))
           if (InvDataSec.data.queryArPriceInfosByQueryIDIndex !== null) {
             nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
             for (
               let i = 0;
               i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
               i++
             ) {
               Alldata.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
             }
           }
         }
       }
       const InvData2 = await API.graphql(
         graphqlOperation(queryArPriceInfosByQueryIDIndex3, {
           QueryId: "P",
           limit: 10000,
           filter:
           {
             ItemCode: { eq: item.Product_Code },
             OrderReceiptCode: { eq: "" },
             ShipmentCode: { eq: "" },
             EUARBmgmtCode: { eq: "" },
             ActualFlag:{eq:"0"},
             UseGroupClassification:{eq: useGroupClassification}
           }
         })
       );
       if (InvData2.data.queryArPriceInfosByQueryIDIndex !== null) {
         let nextToken2 = InvData.data.queryArPriceInfosByQueryIDIndex.nextToken;
         Alldata2 = InvData2.data.queryArPriceInfosByQueryIDIndex.items;
         while (nextToken2 !== null) {
           let InvDataSec2 = await API.graphql(
             graphqlOperation(queryArPriceInfosByQueryIDIndex3, {
               QueryId: "P",
               limit: 10000,
               nextToken: nextToken2,
               filter: {
                 ItemCode: { eq: item.Product_Code },
                 OrderReceiptCode: { eq: "" },
                 ShipmentCode: { eq: "" },
                 EUARBmgmtCode: { eq: "" },
                 ActualFlag:{eq:"0"},
                 UseGroupClassification:{eq: useGroupClassification}
               }
             }
             ))
           if (InvDataSec2.data.queryArPriceInfosByQueryIDIndex) {
             nextToken2 = InvDataSec2.data.queryArPriceInfosByQueryIDIndex.nextToken;
             for (
               let i = 0;
               i < InvDataSec2.data.queryArPriceInfosByQueryIDIndex.items.length;
               i++
             ) {
               Alldata2.push(InvDataSec2.data.queryArPriceInfosByQueryIDIndex.items[i]);
             }
           }
         }
       }
       if (Alldata.length === 0 && Alldata2.length === 0) {
         if ((item.SuppName).includes("ダウ・東レ_BOOK") || (item.SuppName).includes("ダウ・東レ_特価")) {
           handleSetMsgId("エラー");
           handleSetMsgText("選択したエンドユーザーはこの品目では東レエンドユーザ特価ではないため、選択出来ません");
           handleSetMsgBtnOK("はい");
           handleClickOpen2();
           val = item.endUserGroup
           setSearchProgress(false)
         } else {
           handleSetMsgId("エラー");
           handleSetMsgText("選択したエンドユーザーは特価指定されていないため、選択出来ません");
           handleSetMsgBtnOK("はい");
           handleClickOpen2();
           val = item.endUserGroup
           setSearchProgress(false)
         }
       }
       let arr = []
       item.EndUserId = val && val.split(":")[0];
       item.EndUserNm = val && val.split(":")[1]
       item.endUserGroup = val;
       try {
         arr = await SalesUnitPriceScaleFun(item)
       } catch (error) {
         if (error.message === 'No current user') {
           // ユーザープールに障害が発生した場合
           console.log('User pool is invalid.Please reauthenticate.');
           // ログイン画面に戻る
           signOut();
         } else {
           // 他のエラーを処理する
           console.log('An error occurred:', error);
         }
       }
       let SalesUnitPriceObj = await SalespriceInfo(item, arr,index)
       item.BulkShippingFee = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.BulkShippingFee
       item.CoolDeliveryCharge = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.CoolDeliveryCharge
       item.SalesUnitPriceScale = SalesUnitPriceObj.price
       item.PurchUnitPriceScale = await PurchpriceInfo(item, arr,index)
       PriceCalculation(orderinfo);
     }
     setSearchProgress(false)
   }
   const updateQuote_Reg_flag = async () => {
     updateArAgencyInfos("9", (updateStatus) => {
       history.push({
         pathname: "/Orderlist",
         state: { QuoteNumber: QuoteNumber },
       });
     })
   }
   const getListArSalesRepresentatives = async (SalesGroupInfo, v1, v2) => {
     let infoArr = []
     let list = await API.graphql(graphqlOperation(listArSalesRepresentatives,
       {
         filter: {
           SalesGroup: { eq: SalesGroupInfo },
           OrderReceiptCode: { eq: v1 },
           ItemGroup: { eq: v2 }
         }
       }));
     if (list.data.listArSalesRepresentatives !== null) {
       let nextToken = list.data.listArSalesRepresentatives.nextToken;
       infoArr = list.data.listArSalesRepresentatives.items;
       while (nextToken !== null) {
         let InvDataSec = await API.graphql(graphqlOperation(listArSalesRepresentatives, {
           limit: 10000,
           filter: {
             SalesGroup: { eq: SalesGroupInfo },
             OrderReceiptCode: { eq: v1 },
             ItemGroup: { eq: v2 }
           }, nextToken: nextToken
         }));
         nextToken = InvDataSec.data.listArSalesRepresentatives.nextToken;
         for (let i = 0; i < InvDataSec.data.listArSalesRepresentatives.items.length; i++) {
           infoArr.push(InvDataSec.data.listArSalesRepresentatives.items[i]);
         }
       }
     }
     return infoArr
   }
   const ccEmail = (SalesGroupInfo)=>{
    let emails = ""
    switch(SalesGroupInfo){
      case "162":emails = global.ArGlob.EMWcc;break;
      case "141":emails = global.ArGlob.EMScc;break;
      case "181":emails = global.ArGlob.EMCcc;break;
    }
    return emails;
   }
   const getTitle = (val)=>{
    let title = "";
    switch(val){
      case "4":title = "EMW";break;
      case "2":title = "EMS";break;
      case "3":title = "EMC";break;
    }
    return title;
   }
   const SalesConfirmClick = async (row) => {
    setSearchProgress(true)
     let SalesGroupInfo = row.SalesGroupInfo ? row.SalesGroupInfo : ""
     let v1 = row.SelectContractor ? row.SelectContractor : ""
     let v2 = row.ItemGroup ? row.ItemGroup : ""
     let infoArr = await getListArSalesRepresentatives(SalesGroupInfo, v1, v2);
     let Emails = []
     let msgUrlT = global.ArGlob.OrderEmd + row.QuoteNumber;
     const subdivision_fee = row.BulkShippingFee
     const unit = BulkShippingFeeUnit(row)
     const ccemails = ccEmail(row.SalesGroupInfo)
     const emailContent = `
     <p>
     営業担当者　各位<br><br>
     お疲れ様です。<br>下記の通り価格の御確認申し上げます。<br><br>
     <table>
         <tr>
           <td align="right">管理番号：</td>
           <td>${row.OrderDetailsNumber ? row.OrderDetailsNumber : ""}</td>
         </tr>
         <tr>
         <td align="right">受注先：</td>
         <td>${row.SelectContractorNm ? row.SelectContractorNm : ""}</td>
       </tr>
       <tr>
         <td align="right">品名：</td>
         <td>${row.Product_Name_j ? row.Product_Name_j : ""}</td>
       </tr>
       <tr>
         <td align="right">数量：</td>
         <td>${row.OrderQuantity ? row.OrderQuantity + "　" + row.BaseNumUnit : ""}</td>
       </tr>
       <tr>
       <td align="right">単価：</td>
       <td>\\${row.SalesUnitPriceScale ? numtomoney(row.SalesUnitPriceScale) + "/" + row.PriceUnit : ""}</td>
     </tr>
       <tr>
         <td align="right">規定数量(MOQ)：</td>
         <td>${row.RegQty ? row.RegQty + "　" + row.BaseNumUnit : ""}</td>
       </tr>
       <tr>
         <td align="right">小分け可否：</td>
         <td>${row.BulkSales === '0' ? "不可" : "可"}</td>
       </tr>
       <tr>
         <td align="right">小分け手数料：</td>
         <td>\\${Number(moneytonum(subdivision_fee)) !== 0 ? numtomoney(subdivision_fee) + '/' + unit : "0"}</td>
       </tr>
       <tr>
         <td align="right">出荷先：</td>
         <td>${row.ShippingDestinationNm ? row.ShippingDestinationNm : ""}</td>
       </tr>
       <tr>
         <td align="right">エンドユーザー：</td>
         <td>${row.EndUserNm ? row.EndUserNm : ""}</td>
       </tr>
       <tr>
         <td align="right">備考：</td>
         <td>${row.InHouseRe ? row.InHouseRe : ""}</td>
       </tr>
       <tr>
         <td align="right">ARBOS URL：</td>
         <td>${msgUrlT ? `<a href="${msgUrlT}">${msgUrlT}</a>` : ""}</td>
       </tr>
     </table>
     </p>
 `;
     infoArr.length > 0 && infoArr.forEach((item) => { Emails.push(item.SalespersonEmail) })
     const bodyContent = {
       templete: "a7",
       subject: `エア・ブラウンARBOS_営業確認_${row.SelectContractorNm}_${row.Product_Code}`,
       Cc: ccemails,
       msgUrl: "",
       txtEnd: "",
       receiveUser: "",
       toAgencyEmails:Emails,
       message:emailContent,
       replyTo: ccemails
     };
     const body = JSON.stringify(bodyContent);
     const requestOptions = {
       method: "POST",
       body,
     };
     await fetch(global.ArGlob.EmailEndPoint, requestOptions);
     updateArAgencyInfos("8", (updateStatus) => {
       history.push({
         pathname: "/Orderlist",
         state: { QuoteNumber: QuoteNumber },
       });
     })
     setSearchProgress(false)
   }
   function bubbleSort(arr,Attributes) {
     const max = arr.length - 1;
     for (let j = 0; j < max; j++) {
       let done = true;
       for (let i = 0; i < max - j; i++) {
         if (arr[i][Attributes].localeCompare(arr[i + 1][Attributes]) > 0) {
           let temp = arr[i];
           arr[i] = arr[i + 1];
           arr[i + 1] = temp;
           done = false;
         }
       }
       if (done) {
         break;
       }
     }
     return arr;
   }
   const reducercontractor = (state, action) => {
     let obj = [];
     let index = "";
     let obj1 = [];
     switch (action.type) {
       case QUERY_ZA:
         index = "";
         for (let i = 0; i < action.Invs.length; i++) {
           obj.push(action.Invs[i].EndUserCode + ":" + action.Invs[i].Name1)
           obj1.push(action.Invs[i])
         }
         return { ...state, Invs: obj, list: obj1 };
       case QUERY_SH:
         index = "";
         for (let i = 0; i < action.Invs.length; i++) {
           let find = obj.findIndex((item) => {
             return item === action.Invs[i].CustomerReceiptCode + ":" + action.Invs[i].Name1
           })
           if (find <= -1) {
             obj.push(action.Invs[i].CustomerReceiptCode + ":" + action.Invs[i].Name1);
           }
         }
         return { ...state, Invs: obj };
       default:
         return state;
     }
   };
   let [selectContractorOptions, setContractorGroup] = useState("")
   let [endUserOptions, setEndUsersOptions] = useReducer(
     reducercontractor,
     initialcontractor
   );
   let [ShipingOptions, setShipingOptions] = useReducer(
     reducercontractor,
     initialcontractor
   );
   const getAddressList = async (OrderReceiptCode) => {
     let listCustomerAddressList = []
     let EndUserInfoLIst = []
     if (OrderReceiptCode) {
       OrderRecipientCodeId = OrderReceiptCode;
       const EndUseList = await API.graphql(
         graphqlOperation(arEndUserByReceiptCode, {
           RecipientCode: OrderRecipientCodeId,
         })
       );
       if (EndUseList.data.ArEndUsersByOrderRecipientCode !== null) {
         EndUserInfoLIst = EndUseList.data.ArEndUsersByOrderRecipientCode.items;
         EndUserInfoLIst = bubbleSort(EndUserInfoLIst,'SearchTerm');
         setEndUsersOptions({
           type: QUERY_ZA,
           Invs: EndUserInfoLIst,
         });
       }
       let listAddressInfo = await API.graphql(
         graphqlOperation(arCustomerAddressByOrderRecipientCode, {
           OrderRecipientCode: OrderRecipientCodeId,
         })
       );
       if (listAddressInfo.data.queryArCustomerAddressesByOrderRecipientCodeIndex !== null) {
         listCustomerAddressList = listAddressInfo.data.queryArCustomerAddressesByOrderRecipientCodeIndex.items;
         listCustomerAddressList = bubbleSort(listCustomerAddressList,'SearchTerm1');
         setAddress(listCustomerAddressList);
         setShipingOptions({
           type: QUERY_SH,
           Invs: listCustomerAddressList,
         });
       }
     }
     return { EndUserInfoLIst: EndUserInfoLIst, listCustomerAddressList: listCustomerAddressList }
   };
   const handleChangeSalesType = async (e, item) => {
     if (e.target.value === "サンプル") {
       handleClickOpen3();
     } else {
       handleSetMsgId("エラー");
       handleSetMsgText("サンプルから通常購入に変更できません。商品選択画面で他の受注先を選択してください");
       handleSetMsgBtnOK("はい");
       handleClickOpen2();
       e.target.value === "サンプル"
       item.SalesType = "サンプル"
       PriceCalculation(orderinfo)
     }
   }
   const handleInHouseReChange = (e, item) => {
     if (item) {
       item.InHouseRe = e.target.value;
       setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
     }
   }
   const handlePurchOrderReChange = (e, item) => {
     if (item) {
       item.PurchOrderRe = e.target.value;
       setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
     }
   }
   const handleTorayEDIReChange = (e, item) => {
     if (item) {
       item.TorayEDIRe = e.target.value;
       setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
     }
   }
   const handleFukuiWarehouseCSVReChange = (e, item) => {
     if (item) {
       item.FukuiWarehouseCSVRe = e.target.value;
       setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
     }
   }
   const handleCompanMemoChange = (e, item) => {
     if (item) {
       item.CompanMemo = e.target.value;
       setOrderinfo(JSON.parse(JSON.stringify(orderinfo)))
     }
   }
   const handleChange1 = async (value, item, addressList, order,index) => {
    setSearchProgress(true)
     let leadTime = 0;
     if (value === "") {
       item.OrderAddress = ""
       item.OrderTelNo = "";
     } else {
       let id = value.split(":");
       for (let j = 0; j < addressList.length; j++) {
         if (addressList[j].CustomerReceiptCode === id[0]) {
          if ((item.SuppName === "ダウ・東レ_特価" || item.SuppName === "ダウ・東レ_BOOK") && addressList[j].TorayShipCode === "") {
             handleSetMsgId("エラー");
             handleSetMsgText("受注先出荷先紐づけマスタに東レ_出荷先コードが存在しません");
             handleSetMsgBtnOK("はい");
             handleClickOpen2();
             setSearchProgress(false)
           } else {
            if (addressList[j].PostCode) {
              leadTime = await FukuiSeinoLTFun(addressList[j].PostCode)
              if(leadTime === 0){
                handleSetMsgId("エラー");
                handleSetMsgText("【福井倉庫西濃リードタイム表】対象郵便番号が存在しません");
                handleSetMsgBtnOK("はい");
                handleClickOpen2();
                setSearchProgress(false);
                break;
              }
          }
            if(item.SuppName === "ダウ・東レ_特価" || item.SuppName === "ダウ・東レ_BOOK"){
              item.TorayShipCode = addressList[j].TorayShipCode
            }
            item.FukuiSeinoLT = leadTime
             item.telText = addressList[j].PhoneNumber
             item.emailText = addressList[j].EmailAddress
             item.ShippingDestination = addressList[j].CustomerReceiptCode
             item.ShippingDestinationNm = addressList[j].Name1
             item.ShippingData = addressList[j].CustomerReceiptCode + ":" + addressList[j].Name1;
             let PostCodeText = "";
             if (addressList[j].PostCode.length > 3) {
               PostCodeText = addressList[j].PostCode.substring(0, 3) + "-" + addressList[j].PostCode.substring(3);
             }
             item.OrderAddress = PostCodeText + "　" + addressList[j].PlaceName + addressList[j].city + addressList[j].PlaceName3
             item.OrderTelNo = addressList[j].Tel
             item.OrderPostCode = addressList[j].PostCode
             if(item.ShippingDestination){
              if (item.Sales_Document_Type === "ZT31" || item.Sales_Document_Type === "ZS01") {
                let date = new Date()
                if (date.getHours() >= 0 && date.getHours() < 12) {
                  let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (2 + parseInt(leadTime)))
                  item.ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
                } else if (date.getHours() >= 12 && date.getHours() < 24) {
                  let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (3 + parseInt(leadTime)))
                  item.ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
                }
              }
             }
           }
           break;
         }
       }
     }
     if (item.SelectContractor !== "AA89104100" && item.SelectContractor !== "AA89106200" && item.SelectContractor !== "AA89108100") {
         let arr = await SalesUnitPriceScaleFun(item)
         let SalesUnitPriceObj = await SalespriceInfo(item, arr,index)
       item.BulkShippingFee = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.BulkShippingFee
       item.CoolDeliveryCharge = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.CoolDeliveryCharge
       item.SalesUnitPriceScale = SalesUnitPriceObj.price
       item.PurchUnitPriceScale = await PurchpriceInfo(item, arr,index)
     } else {
       let arr = await SalesUnitPriceScaleFun(item)
       item.PurchUnitPriceScale = await PurchpriceInfo(item, arr,index)
       item.SalesUnitPriceScale = 0
     }
     PriceCalculation(order)
     setSearchProgress(false)
   };
   //福井西濃LT
   const FukuiSeinoLTFun = async (postCode) => {
     let leadTime = 0
     let infoArr = []
     const PostCodeInfo = await API.graphql(graphqlOperation(queryArFukuiWHSeinoLTSByPostCodeIndex,
       { PostCode: postCode }));
     if (PostCodeInfo.data.queryArFukuiWHSeinoLTSByPostCodeIndex !== null) {
       let nextToken = PostCodeInfo.data.queryArFukuiWHSeinoLTSByPostCodeIndex.nextToken;
       infoArr = PostCodeInfo.data.queryArFukuiWHSeinoLTSByPostCodeIndex.items;
       while (nextToken !== null) {
         let InvDataSec = await API.graphql(
           graphqlOperation(queryArFukuiWHSeinoLTSByPostCodeIndex, {
             PostCode: postCode,
             limit: 10000,
             nextToken: nextToken,
           }
           ))
         nextToken = InvDataSec.data.queryArFukuiWHSeinoLTSByPostCodeIndex.nextToken;
         for (
           let i = 0;
           i < InvDataSec.data.queryArFukuiWHSeinoLTSByPostCodeIndex.items.length;
           i++
         ) {
           infoArr.push(InvDataSec.data.queryArFukuiWHSeinoLTSByPostCodeIndex.items[i]);
         }
       }
     }
     if (infoArr.length > 0) {
      if(infoArr[0] && infoArr[0].leadTime){
        leadTime = infoArr[0].leadTime
      }
     }
     return leadTime
   }
   function orderClick(saveOrder) {
     updateArOrders(saveOrder)
   }
   function goback() {
     gobackUpdate();
   }
   const BulkShippingFeeUnit = (row)=>{
    let unit = ""
    if (row.SuppName === "長瀬産業名古屋(消泡剤)_特価" || row.SuppName === "長瀬産業名古屋(変性オイル)_特価" || row.SuppName === "長瀬産業名古屋(シラン・エマルジョン)_特価" || row.SuppName === "長瀬産業名古屋(塗料コーティング)_特価") {
      unit = "KG"
    }else{
      unit = "オーダー"
    }
   return unit
  }
  //  const subdivisionfee = (row) => {
  //    let postageSum = 0;
  //    let matchResult = (row.RegQty).match(/\d+/);
  //    let RegQtyVal = matchResult ? parseInt(matchResult[0], 10) : 1;
  //    let CoolFlg = "0";
  //    let BulkShippingFee = isNaN(parseInt(row.BulkShippingFee)) ? 0 : parseInt(row.BulkShippingFee)
  //    if((parseInt(row.OrderQuantity,10) % RegQtyVal) !== 0){
  //     if (row.SuppName === "兼松ペトロ(MOLYKOTE)_BOOK") {
  //       if (BulkShippingFee) {
  //         postageSum += BulkShippingFee
  //       }
  //     }
  //     if (row.SuppName === "兼松ペトロ(MOLYKOTE)_特価") {
  //      if (BulkShippingFee) {
  //        postageSum += BulkShippingFee
  //      }
  //    }
  //     if (row.SuppName === "兼松ペトロ(型取り剤)_BOOK") {
  //      if (BulkShippingFee) {
  //        postageSum += BulkShippingFee
  //      }
  //    }
  //     if (row.SuppName === "兼松ペトロ(型取り剤)_特価") {
  //      if (BulkShippingFee) {
  //        postageSum += BulkShippingFee
  //      }
  //    }
  //     if (row.SuppName === "稲畑産業(XIAMETER)_特価") {
  //       let Capacity = convertToKGPerUnit(row.Capacity + ":" + row.CapacityUnit)
  //       if (BulkShippingFee) {
  //         if ((Capacity * parseInt(row.OrderQuantity)) < parseInt("10")) {
  //           postageSum += BulkShippingFee
  //         }
  //       }
  //     }
  //     if (row.SuppName === "稲畑産業(SILANE)_特価") {
  //       let Capacity = convertToKGPerUnit(row.Capacity + ":" + row.CapacityUnit)
  //       if (row.Product_Code === "Z-6210-1KG") {
  //         if (BulkShippingFee) {
  //           if ((Capacity * parseInt(row.OrderQuantity)) < parseInt("6")) {
  //             postageSum += BulkShippingFee
  //           }
  //         }
  //       } else {
  //         if (BulkShippingFee) {
  //           if ((Capacity * parseInt(row.OrderQuantity)) < parseInt("10")) {
  //             postageSum += BulkShippingFee
  //           }
  //         }
  //       }
  //     }
  //     if (row.SuppName === "稲畑産業(変性オイル)_特価") {
  //       let Capacity = convertToKGPerUnit(row.Capacity + ":" + row.CapacityUnit)
  //       if (BulkShippingFee) {
  //         if ((Capacity * parseInt(row.OrderQuantity)) < parseInt("6")) {
  //           postageSum += BulkShippingFee
  //         }
  //       }
  //     }
  //     if (row.SuppName === "長瀬産業名古屋(消泡剤)_特価") {
  //       let Capacity = convertToKGPerUnit(row.Capacity + ":" + row.CapacityUnit)
  //       let RegQty = (row.RegQty).replace(/>/g, "")
  //       if (BulkShippingFee) {
  //         if (parseInt(row.OrderQuantity) < parseInt(RegQty)) {
  //           postageSum += BulkShippingFee * (parseInt(row.OrderQuantity) * Capacity)
  //         }
  //       }
  //     }
  //     if (row.SuppName === "長瀬産業名古屋(変性オイル)_特価") {
  //       let Capacity = convertToKGPerUnit(row.Capacity + ":" + row.CapacityUnit)
  //       if (BulkShippingFee) {
  //         postageSum += BulkShippingFee * (parseInt(row.OrderQuantity) * Capacity)
  //       }
  //     }
  //     if (row.SuppName === "長瀬産業東京(塗料添加剤)_特価") {
  //       let Capacity = convertToKGPerUnit(row.Capacity + ":" + row.CapacityUnit)
  //       if (BulkShippingFee) {
  //         if ((parseInt(row.OrderQuantity) * Capacity) < 5) {
  //           postageSum += BulkShippingFee
  //         }
  //       }
  //     }
  //     if (row.SuppName === "長瀬産業名古屋(シラン・エマルジョン)_特価") {
  //      let Capacity = convertToKGPerUnit(row.Capacity + ":" + row.CapacityUnit)
  //      let RegQty = (row.RegQty).replace(/>/g, "")
  //      if (BulkShippingFee) {
  //        if (parseInt(row.OrderQuantity) < parseInt(RegQty)) {
  //          postageSum += BulkShippingFee * (parseInt(row.OrderQuantity) * Capacity)
  //        }
  //      }
  //    }
  //    if (row.SuppName === "長瀬産業名古屋(塗料コーティング)_特価") {
  //      let Capacity = convertToKGPerUnit(row.Capacity + ":" + row.CapacityUnit)
  //      let RegQty = (row.RegQty).replace(/>/g, "")
  //      if (BulkShippingFee) {
  //        if (parseInt(row.OrderQuantity) < parseInt(RegQty)) {
  //          postageSum += BulkShippingFee * (parseInt(row.OrderQuantity) * Capacity)
  //        }
  //      }
  //    }
  //    if (row.SuppName === "Authorized_BOOK") {
  //     if (BulkShippingFee) {
  //       postageSum += BulkShippingFee
  //     }
  //   }
  //    if (row.SuppName === "ダウ・東レ_BOOK") {
  //      if (BulkShippingFee) {
  //        postageSum += BulkShippingFee
  //      }
  //    }
  //    if (row.SuppName === "ダウ・東レ_特価") {
  //      if (BulkShippingFee) {
  //        postageSum += BulkShippingFee
  //      }
  //    }
  //   }else{
  //       // 注文登録画面の数量は規定数量の倍数になる場合
  //       if (row.SuppName === "稲畑産業(XIAMETER)_特価" ||
  //           row.SuppName === "稲畑産業(SILANE)_特価" ||
  //           row.SuppName === "稲畑産業(変性オイル)_特価" ||
  //           row.SuppName === "長瀬産業名古屋(消泡剤)_特価" ||
  //           row.SuppName === "長瀬産業名古屋(変性オイル)_特価" ||
  //           row.SuppName === "長瀬産業東京(塗料添加剤)_特価" ||
  //           row.SuppName === "長瀬産業名古屋(シラン・エマルジョン)_特価" ||
  //           row.SuppName === "長瀬産業名古屋(塗料コーティング)_特価" ||
  //           row.SuppName === "Authorized_BOOK" ||
  //           row.SuppName === "ダウ・東レ_BOOK" ||
  //           row.SuppName === "ダウ・東レ_特価" ||
  //           row.SuppName === "兼松ペトロ(MOLYKOTE)_BOOK" ||
  //           row.SuppName === "兼松ペトロ(MOLYKOTE)_特価" ||
  //           row.SuppName === "兼松ペトロ(型取り剤)_BOOK" ||
  //           row.SuppName === "兼松ペトロ(型取り剤)_特価")
  //           {
  //             // クール便送料は計算されません。
  //             CoolFlg = "1"
  //           }
  //   }
  //   if (row.SuppName === "アクト_特価") {
  //     if (BulkShippingFee) {
  //       postageSum += BulkShippingFee
  //     }
  //   }
  //   if (row.CoolDeliveryCharge && CoolFlg === "0") {
  //     postageSum += Number(row.CoolDeliveryCharge)
  //   }
  //    return postageSum;
  //  }
   //合計金額
   function PriceCalculation(order) {
     let sum = 0;
     let postageSum = 0;
     for (let j = 0; j < order.length; j++) {
       let itemSum = 0;
       let itemPostageSum = 0;
       //製品合計
       if (order[j].UnitPriceAftDis) {
         if (order[j].PriceUnit === "EA") {
           sum = sum + parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].UnitPriceAftDis));
           itemSum = parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].UnitPriceAftDis));
         } else {
           sum = sum + parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].UnitPriceAftDis)) * parseInt(order[j].Capacity);
           itemSum = parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].UnitPriceAftDis)) * parseInt(order[j].Capacity);
         }
       } else {
         if (order[j].PriceUnit === "EA") {
           sum = sum + parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].SalesUnitPriceScale));
           itemSum = parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].SalesUnitPriceScale));
         } else {
           sum = sum + parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].SalesUnitPriceScale)) * parseInt(order[j].Capacity);
           itemSum = parseInt(order[j].OrderQuantity) * parseInt(moneytonum(order[j].SalesUnitPriceScale)) * parseInt(order[j].Capacity);
         }
       }
       //送料/手数料
       if (order[j].SelectContractor !== "AA89104100" && order[j].SelectContractor !== "AA89108100" && order[j].SelectContractor !== "AA89106200") {
        let matchResult = (order[j].RegQty).match(/\d+/);
        let RegQtyVal = matchResult ? parseInt(matchResult[0], 10) : 1;
        let CoolFlg = "0";
        let BulkShippingFee = isNaN(parseInt(order[j].BulkShippingFee)) ? 0 : parseInt(order[j].BulkShippingFee)
        if((parseInt(order[j].OrderQuantity,10) % RegQtyVal) !== 0){
          if (order[j].SuppName === "兼松ペトロ(MOLYKOTE)_BOOK") {
            if (BulkShippingFee) {
              postageSum += BulkShippingFee
              itemPostageSum += BulkShippingFee
            }
          }
          if (order[j].SuppName === "兼松ペトロ(MOLYKOTE)_特価") {
           if (BulkShippingFee) {
             postageSum += BulkShippingFee
             itemPostageSum += BulkShippingFee
           }
         }
          if (order[j].SuppName === "兼松ペトロ(型取り剤)_BOOK") {
           if (BulkShippingFee) {
             postageSum += BulkShippingFee
             itemPostageSum += BulkShippingFee
           }
         }
         if (order[j].SuppName === "兼松ペトロ(型取り剤)_特価") {
          if (BulkShippingFee) {
            postageSum += BulkShippingFee
            itemPostageSum += BulkShippingFee
          }
         }
          if (order[j].SuppName === "稲畑産業(XIAMETER)_特価") {
            let Capacity = convertToKGPerUnit(order[j].Capacity + ":" + order[j].CapacityUnit)
            if (BulkShippingFee) {
              if ((Capacity * parseInt(order[j].OrderQuantity)) < parseInt("10")) {
                postageSum += BulkShippingFee
                itemPostageSum += BulkShippingFee
              }
            }
          }
          if (order[j].SuppName === "稲畑産業(SILANE)_特価") {
            let Capacity = convertToKGPerUnit(order[j].Capacity + ":" + order[j].CapacityUnit)
            if (order[j].Product_Code === "Z-6210-1KG") {
              if (BulkShippingFee) {
                if ((Capacity * parseInt(order[j].OrderQuantity)) < parseInt("6")) {
                  postageSum += BulkShippingFee
                  itemPostageSum += BulkShippingFee
                }
              }
            } else {
              if (BulkShippingFee) {
                if ((Capacity * parseInt(order[j].OrderQuantity)) < parseInt("10")) {
                  postageSum += BulkShippingFee
                  itemPostageSum += BulkShippingFee
                }
              }
            }
          }
          if (order[j].SuppName === "稲畑産業(変性オイル)_特価") {
            let Capacity = convertToKGPerUnit(order[j].Capacity + ":" + order[j].CapacityUnit)
            if (BulkShippingFee) {
              if ((Capacity * parseInt(order[j].OrderQuantity)) < parseInt("6")) {
                postageSum += BulkShippingFee
                itemPostageSum += BulkShippingFee
              }
            }
          }
          if (order[j].SuppName === "長瀬産業名古屋(消泡剤)_特価") {
            let Capacity = convertToKGPerUnit(order[j].Capacity + ":" + order[j].CapacityUnit)
            let RegQty = (order[j].RegQty).replace(/>/g, "")
            if (BulkShippingFee) {
              if (parseInt(order[j].OrderQuantity) < parseInt(RegQty)) {
                postageSum += BulkShippingFee * (parseInt(order[j].OrderQuantity) * Capacity)
                itemPostageSum += BulkShippingFee * (parseInt(order[j].OrderQuantity) * Capacity)
              }
            }
          }
          if (order[j].SuppName === "長瀬産業名古屋(変性オイル)_特価") {
            let Capacity = convertToKGPerUnit(order[j].Capacity + ":" + order[j].CapacityUnit)
            if (BulkShippingFee) {
              postageSum += BulkShippingFee * (parseInt(order[j].OrderQuantity) * Capacity)
              itemPostageSum += BulkShippingFee * (parseInt(order[j].OrderQuantity) * Capacity)
            }
          }
          if (order[j].SuppName === "長瀬産業東京(塗料添加剤)_特価") {
            let Capacity = convertToKGPerUnit(order[j].Capacity + ":" + order[j].CapacityUnit)
            if (BulkShippingFee) {
              if ((parseInt(order[j].OrderQuantity) * Capacity) < 5) {
                postageSum += BulkShippingFee
                itemPostageSum += BulkShippingFee
              }
            }
          }
          if (order[j].SuppName === "長瀬産業名古屋(シラン・エマルジョン)_特価") {
           let Capacity = convertToKGPerUnit(order[j].Capacity + ":" + order[j].CapacityUnit)
           let RegQty = (order[j].RegQty).replace(/>/g, "")
           if (BulkShippingFee) {
             if (parseInt(order[j].OrderQuantity) < parseInt(RegQty)) {
               postageSum += BulkShippingFee * (parseInt(order[j].OrderQuantity) * Capacity)
               itemPostageSum += BulkShippingFee * (parseInt(order[j].OrderQuantity) * Capacity)
             }
           }
         }
         if (order[j].SuppName === "長瀬産業名古屋(塗料コーティング)_特価") {
           let Capacity = convertToKGPerUnit(order[j].Capacity + ":" + order[j].CapacityUnit)
            let RegQty = (order[j].RegQty).replace(/>/g, "")
            if (BulkShippingFee) {
              if (parseInt(order[j].OrderQuantity) < parseInt(RegQty)) {
                postageSum += BulkShippingFee * (parseInt(order[j].OrderQuantity) * Capacity)
                itemPostageSum += BulkShippingFee * (parseInt(order[j].OrderQuantity) * Capacity)
              }
            }
         }
         if (order[j].SuppName === "Authorized_BOOK") {
          if (BulkShippingFee) {
            postageSum += BulkShippingFee
            itemPostageSum += BulkShippingFee
          }
        }
          if (order[j].SuppName === "ダウ・東レ_BOOK") {
            if (BulkShippingFee) {
              postageSum += BulkShippingFee
              itemPostageSum += BulkShippingFee
            }
          }
          if (order[j].SuppName === "ダウ・東レ_特価") {
            if (BulkShippingFee) {
              postageSum += BulkShippingFee
              itemPostageSum += BulkShippingFee
            }
          }
        }else{
          // 注文登録画面の数量は規定数量の倍数になる場合
          if (order[j].SuppName === "稲畑産業(XIAMETER)_特価" ||
              order[j].SuppName === "稲畑産業(SILANE)_特価" ||
              order[j].SuppName === "稲畑産業(変性オイル)_特価" ||
              order[j].SuppName === "長瀬産業名古屋(消泡剤)_特価" ||
              order[j].SuppName === "長瀬産業名古屋(変性オイル)_特価" ||
              order[j].SuppName === "長瀬産業東京(塗料添加剤)_特価" ||
              order[j].SuppName === "長瀬産業名古屋(シラン・エマルジョン)_特価" ||
              order[j].SuppName === "長瀬産業名古屋(塗料コーティング)_特価" ||
              order[j].SuppName === "Authorized_BOOK" ||
              order[j].SuppName === "ダウ・東レ_BOOK" ||
              order[j].SuppName === "ダウ・東レ_特価" ||
              order[j].SuppName === "兼松ペトロ(MOLYKOTE)_BOOK" ||
              order[j].SuppName === "兼松ペトロ(MOLYKOTE)_特価" ||
              order[j].SuppName === "兼松ペトロ(型取り剤)_BOOK" ||
              order[j].SuppName === "兼松ペトロ(型取り剤)_特価")
              {
                // クール便送料は計算されません。
                CoolFlg = "1"
              }
        }
       if (order[j].SuppName === "アクト_特価") {
        if (BulkShippingFee) {
          postageSum += BulkShippingFee
          itemPostageSum += BulkShippingFee
        }
      }
        if (order[j].CoolDeliveryCharge && CoolFlg === "0") {
            postageSum += Number(order[j].CoolDeliveryCharge)
            itemPostageSum += Number(order[j].CoolDeliveryCharge)
        }
       }
       order[j].invoiceSubtotal = numtomoney(itemSum, 0);
       order[j].invoiceTotal = numtomoney(itemPostageSum, 0);
       order[j].invoiceFax = numtomoney(Math.round((itemSum + itemPostageSum) * TAX_RATE), 0);
       order[j].invoicePayTotal = numtomoney(Math.round(itemSum + itemPostageSum + Math.round(itemSum + itemPostageSum) * 0), 0);
       order[j].unitPrice = Math.round((itemSum + (itemSum + itemPostageSum) * 0 + itemPostageSum) / parseInt(order[j].OrderQuantity))
     }
     invoiceSubtotal = numtomoney(sum, 0);
     invoiceTotal = numtomoney(postageSum, 0);
     invoiceFax = numtomoney(Math.round((sum + postageSum) * TAX_RATE), 0);
     invoicePayTotal = numtomoney(sum + postageSum + Math.round(sum + postageSum) * 0, 0);
     setOrderinfo(JSON.parse(JSON.stringify(order)));
   }
   const handleSalesUnitPriceScaleChange = async(e, item) => {
     let reg = /^(0|[1-9][0-9]*)$/
     e.target.value = e.target.value.replace(/[^0-9]/g, '')
     if (e.target.value !== "" && !reg.test(e.target.value)) {
       handleSetMsgId("エラー");
       handleSetMsgText("数字のみ入力可能");
       handleSetMsgBtnOK("はい");
       handleClickOpen2();
       e.target.value = item.SalesUnitPriceScale
     }
     item.SalesUnitPriceScale = e.target.value
     if(item.OrderStatus === "8"){
      if(!item.SalesRepresentative){
        item.SalesRepresentative = User_Name
      }
     }
     try {
       PriceCalculation(orderinfo)
     } catch (error) {
       if (error.message === 'No current user') {
         // ユーザープールに障害が発生した場合
         console.log('User pool is invalid.Please reauthenticate.');
         // ログイン画面に戻る
         signOut();
       } else {
         // 他のエラーを処理する
         console.log('An error occurred:', error);
       }
     }
   }
   const gobackUpdate = async () => {
    setSearchProgress(true)
     //一時保存処理
    await updateArAgencyInfos("1", (updateStatus) => {
       if (updateStatus) {
         history.push({
           pathname: "/InventorySearchEmd",
           state: { QuoteNumber: QuoteNumber, DesiredDeliveryDate: moment(hopeDateVal).utcOffset(9).format('YYYY-MM-DD') },
         });
       }
     });
    setSearchProgress(false)
   }
   const updateArAgencyInfos = async (updatStatus, callback,saveOrder) => {
     for (let j = 0; j < orderinfo.length; j++) {
       let item = orderinfo[j];
       let hopedateValue = moment(item.DesiredDeliveryDate).utcOffset(9).format('YYYY-MM-DD');
       if (hopedateValue.length > 17) {
         hopedateValue = hopedateValue + "T00:00.000Z";
       } else if (hopedateValue.length > 0) {
         hopedateValue = hopedateValue + "T00:00:00.000Z";
       } else {
         hopedateValue = "";
       }
       let SalesGroupInfo = item.SalesGroupInfo ? item.SalesGroupInfo : ""
       let v1 = item.SelectContractor ? item.SelectContractor : ""
       let v2 = item.ItemGroup ? item.ItemGroup : ""
       let infoArr = await getListArSalesRepresentatives(SalesGroupInfo, v1, v2)
       let Salesperson = infoArr.length > 0 ? infoArr[0].Salesperson : ""
       let SalespersonName = infoArr.length > 0 ? infoArr[0].SalespersonName : ""
       if(!saveOrder && Salesperson === ""){
        handleSetMsgId("エラー");
        handleSetMsgText(`営業担当者マスタに営業員が存在しません`);
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        setSearchProgress(false)
        return;
       }
       try {
        if(updatStatus === "1" || updatStatus === "2"){
          const result = await API.graphql(
            graphqlOperation(updateArAgencyOrderInfo, {
              input: {
                id: item.id,
                Agency_id: item.Agency_id,
                AgencyEmail: item.AgencyEmail,
                AgencyName: item.Agency_Name,
                GroupName: item.GroupName,
                comments: item.OrderShippingNotes,
                DesiredDeliveryDate: item.DesiredDeliveryDate,
                EndUserId: item.EndUserId,
                EndUserNm: item.EndUserNm,
                OrderAddress: item.OrderAddress,
                OrderChargeName: OrderChargeNameText,
                OrderNumber: item.OrderNumber /* 発注番号 */,
                OrderPostCode: item.OrderPostCode,
                OrderQuantity: parseFloat(item.OrderQuantity),
                OrderRemarks: item.OrderRemarks,
                OrderShipContactNm: item.OrderShipContactNm, /*出荷先担当者名 */
                OrderShippingNotes: item.OrderShippingNotes, /*出荷備考 */
                OrderStatus: updatStatus /*登録完了 一時保存 見積受付*/,
                OrderTelNo: item.OrderTelNo,
                Price: item.SalesUnitPriceScale,
                Sales_Document_Type: item.Sales_Document_Type,
                ShippingDestination: item.ShippingDestination,
                ShippingDestinationNm: item.ShippingDestinationNm,
                ShippingOrderNumber: item.ShippingOrderNumber, /*出荷先注文番号 */
                Wholes_Price: item.UnitPriceAftDis ? item.UnitPriceAftDis : item.SalesUnitPriceScale,
                Segment: item.Segment,
                SelectContractor: item.SelectContractor,
                SelectContractorNm: item.SelectContractorNm,
                ContractorNm: item.SelectContractorNm,
                Contractor: item.SelectContractor,
                invoiceSubtotal: item.invoiceSubtotal,
                invoiceTotal: item.invoiceTotal,
                invoiceFax: item.invoiceFax,
                invoicePayTotal: item.invoicePayTotal,
                unitPrice: item.unitPrice,
                InHouseRe: item.InHouseRe,
                FukuiWarehouseCSVRe: item.FukuiWarehouseCSVRe,
                PurchOrderRe: item.PurchOrderRe,
                TorayEDIRe: item.TorayEDIRe,
                Sales_confirm_flag: updatStatus === "8" ? "1" : item.Sales_confirm_flag,
                Quote_reg_flag: updatStatus === "9" ? "1" : item.Quote_reg_flag,
                CompanMemo: item.CompanMemo,
                Storage_Location_U: item.Storage_Location_U,
                SalesUnitPriceScale: item.SalesUnitPriceScale,
                PurchUnitPriceScale: item.PurchUnitPriceScale,
                UnitPriceAftDis: item.UnitPriceAftDis,
                EndUserIdDirectDelivery: item.EndUserIdDirectDelivery,
                Salesperson: Salesperson,
                SalespersonName:SalespersonName,
                FukuiSeinoLT:item.FukuiSeinoLT,
                SubId:item.SubId,
                TorayShipCode : item.TorayShipCode,
                Plant : item.Plant,
                Supplier:item.Supplier,
                purchasing_Group:item.SalesGroupInfo,
                SalesGroup:item.SalesGroupInfo,
                Tax_Code:"V4",
                Basic_Quantity_Unit:item.BaseNumUnit,
                SalesRepresentative:item.SalesRepresentative,
                ShortestRefTime:item.ShortestRefTime,
                DropDeliveryTime:item.DropDeliveryTime,
                RegQty:item.RegQty,
                BulkSales:item.BulkSales,
                DTStockTier:item.DTStockTier,
                DTNormalLT:item.DTNormalLT,
                BulkShippingFee: item.BulkShippingFee ? item.BulkShippingFee : "",
                CoolDeliveryCharge: item.CoolDeliveryCharge ? item.CoolDeliveryCharge : "",
                SuppName:item.SuppName,
                Usage_System:usageSystem
              },
            })
          );
        }else{
          const result = await API.graphql(
            graphqlOperation(updateArAgencyOrderInfo, {
              input: {
                id: item.id,
                Agency_id: item.Agency_id,
                AgencyEmail: item.AgencyEmail,
                AgencyName: item.Agency_Name,
                GroupName: item.GroupName,
                comments: item.OrderShippingNotes,
                DesiredDeliveryDate: item.DesiredDeliveryDate,
                EndUserId: item.EndUserId,
                EndUserNm: item.EndUserNm,
                OrderAddress: item.OrderAddress,
                OrderChargeName: OrderChargeNameText,
                OrderNumber: item.OrderNumber /* 発注番号 */,
                OrderPostCode: item.OrderPostCode,
                OrderQuantity: parseFloat(item.OrderQuantity),
                OrderRemarks: item.OrderRemarks,
                OrderShipContactNm: item.OrderShipContactNm, /*出荷先担当者名 */
                OrderShippingNotes: item.OrderShippingNotes, /*出荷備考 */
                OrderStatus: updatStatus /*登録完了 一時保存 見積受付*/,
                OrderTelNo: item.OrderTelNo,
                Price: item.SalesUnitPriceScale,
                Sales_Document_Type: item.Sales_Document_Type,
                ShippingDestination: item.ShippingDestination,
                ShippingDestinationNm: item.ShippingDestinationNm,
                ShippingOrderNumber: item.ShippingOrderNumber, /*出荷先注文番号 */
                Wholes_Price: item.UnitPriceAftDis ? item.UnitPriceAftDis : item.SalesUnitPriceScale,
                Segment: item.Segment,
                SelectContractor: item.SelectContractor,
                SelectContractorNm: item.SelectContractorNm,
                ContractorNm: item.SelectContractorNm,
                Contractor: item.SelectContractor,
                invoiceSubtotal: item.invoiceSubtotal,
                invoiceTotal: item.invoiceTotal,
                invoiceFax: item.invoiceFax,
                invoicePayTotal: item.invoicePayTotal,
                unitPrice: item.unitPrice,
                InHouseRe: item.InHouseRe,
                FukuiWarehouseCSVRe: item.FukuiWarehouseCSVRe,
                PurchOrderRe: item.PurchOrderRe,
                TorayEDIRe: item.TorayEDIRe,
                Sales_confirm_flag: updatStatus === "8" ? "1" : item.Sales_confirm_flag,
                Quote_reg_flag: updatStatus === "9" ? "1" : item.Quote_reg_flag,
                CompanMemo: item.CompanMemo,
                Storage_Location_U: item.Storage_Location_U,
                SalesUnitPriceScale: item.SalesUnitPriceScale,
                PurchUnitPriceScale: item.PurchUnitPriceScale,
                UnitPriceAftDis: item.UnitPriceAftDis,
                EndUserIdDirectDelivery: item.EndUserIdDirectDelivery,
                Salesperson: Salesperson,
                SalespersonName:SalespersonName,
                FukuiSeinoLT:item.FukuiSeinoLT,
                SubId:item.SubId,
                TorayShipCode : item.TorayShipCode,
                Plant : item.Plant,
                Supplier:item.Supplier,
                purchasing_Group:item.SalesGroupInfo,
                SalesGroup:item.SalesGroupInfo,
                Tax_Code:"V4",
                Basic_Quantity_Unit:item.BaseNumUnit,
                ShortestRefTime:item.ShortestRefTime,
                DropDeliveryTime:item.DropDeliveryTime,
                RegQty:item.RegQty,
                BulkSales:item.BulkSales,
                DTStockTier:item.DTStockTier,
                DTNormalLT:item.DTNormalLT,
                BulkShippingFee: item.BulkShippingFee ? item.BulkShippingFee : "",
               CoolDeliveryCharge: item.CoolDeliveryCharge ? item.CoolDeliveryCharge : "",
               SuppName:item.SuppName,
               Usage_System:usageSystem
              },
            })
          );
        }
       } catch (error) {
         if (error.message === 'No current user') {
           // ユーザープールに障害が発生した場合
           console.log('User pool is invalid.Please reauthenticate.');
           // ログイン画面に戻る
           signOut();
         } else {
           // 他のエラーを処理する
           console.log('An error occurred:', error);
         }
       }
     }
     callback(true);
   }
   const updateArOrders = async (saveOrder) => {
    setSearchProgress(true)
     if (!saveOrder) {
       for (const index in orderinfo) {
         const item = orderinfo[index];
         if (item.DesiredDeliveryDate === "") {
           handleSetMsgId("エラー");
           handleSetMsgText("希望納期を入力してください");
           handleSetMsgBtnOK("はい");
           handleClickOpen2();
           setSearchProgress(false)
           return;
         }
         if (!item.OrderQuantity) {
          handleSetMsgId("エラー");
          handleSetMsgText("数量が未入力です");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setSearchProgress(false)
          return;
        }
         if (!item.ShippingDestination) {
           handleSetMsgId("エラー");
           handleSetMsgText("出荷先を選択してください");
           handleSetMsgBtnOK("はい");
           handleClickOpen2();
           setSearchProgress(false)
           return;
         }
         if ((item.SalesType !== "サンプル") && (!item.EndUserId)) {
           handleSetMsgId("エラー");
           handleSetMsgText("エンドユーザーを選択してください");
           handleSetMsgBtnOK("はい");
           handleClickOpen2();
           setSearchProgress(false)
           return;
         }
         if(item.SalesType !== "サンプル" && (!item.SalesUnitPriceScale || item.SalesUnitPriceScale === "0")){
          handleSetMsgId("エラー");
          handleSetMsgText("販売単価はブランクまたは0円のため、登録依頼できません");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setSearchProgress(false)
          return;
        }
         //保管場所の取得
         if (!item.Storage_Location_U) {
           handleSetMsgId("エラー");
           handleSetMsgText("品目保管場所マスタ上に保管場所がありません");
           handleSetMsgBtnOK("はい");
           handleClickOpen2();
           setSearchProgress(false)
           return;
         }
         if(item.OrdQty && item.OrderQuantity){
          let OrdQty = isNaN(parseInt(item.OrdQty)) ? 0 : parseInt(item.OrdQty)
          if(OrdQty !== 0 && (Number(item.OrderQuantity) > OrdQty)){
          handleSetMsgId("エラー");
          handleSetMsgText(`1注文可能数は${OrdQty}です。1注文可能数以下に入力してください`);
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setSearchProgress(false)
          return;
          }
      }
       }
     }
     let updatStatus = saveOrder ? "1" : "2"; //1：一時保存 2：登録完了
     updateArAgencyInfos(updatStatus, (updateStatus) => {
       if (updatStatus) {
         if (saveOrder) {
           history.push({
             pathname: "/Orderlist",
             state: { QuoteNumber: QuoteNumber },
           });
         } else {
           history.push({
             pathname: "/OrderConfirmation",
             state: { QuoteNumber: QuoteNumber },
           });
         }
       }
     },saveOrder);
     setSearchProgress(false)
   };
   function txtChangedOrderNo(e, item) {
     if (item.SuppName === "ダウ・東レ_特価" || item.SuppName === "ダウ・東レ_BOOK") {
       if (item.BulkSales === "1" && item.invoiceTotal && item.invoiceTotal !== "0") {
         if (item) {
           let str = "■"
           let newValue = (e.target.value).replace(str, '');
           e.target.value = str + newValue
           item.OrderNumber = e.target.value;
           return;
         }
       }
     }
     handleSetOrderNoText(e.target.value, item);
   }
   function txtChangedOrderShipContactNmText(e, item) {
     handleSetOrderShipContactNmText(e.target.value, item);
   }
   function txtChangedShippingOrderNumberText(e, item) {
     handleShippingOrderNumberText(e.target.value, item);
   }
   function txtChangedOrderChargeNameText(e) {
     handleSetOrderChargeNameText(e.target.value);
   }
   function txtChangedOrderRemarksText(e, item) {
     handleSetOrderRemarksText(e.target.value, item);
   }
   const [state, setState] = React.useState({
     checkedB: true,
   });
   function changeOinput(event, item) {
     if (item) {
      if(item.SuppName === "Authorized_BOOK"){
        if(event.target.value !== "AM" && event.target.value !== "PM" && (event.target.value).trim() !== ""){
          handleSetMsgId("エラー");
          handleSetMsgText("AMかPMまたはブランクのみで入力してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          event.target.value = "";
          return;
        }
      }
       item.OrderShippingNotes = event.target.value;
     }
   }
   const handleChange = (event) => {
     setState({ ...state, [event.target.name]: event.target.checked });
   };
   const addDays = async (date, days) => {
     let holidays = [];
     const listArcalendarsList = await API.graphql(
       graphqlOperation(listArcalendars, {
         filter: { IsWorkday: { eq: "0" } },
         limit: 10000,
       }))
     if (listArcalendarsList.data.listArcalendars !== null) {
       let nextToken = listArcalendarsList.data.listArcalendars.nextToken;
       holidays = listArcalendarsList.data.listArcalendars.items;
       while (nextToken !== null) {
         let InvDataSec = await API.graphql(
           graphqlOperation(listArcalendars, {
             filter: { IsWorkday: { eq: "0" } },
             limit: 10000,
             nextToken: nextToken,
           }))
         nextToken = InvDataSec.data.listArcalendars.nextToken;
         for (
           let i = 0;
           i < InvDataSec.data.listArcalendars.items.length;
           i++
         ) {
           holidays.push(InvDataSec.data.listArcalendars.items[i]);
         }
       }
     }
     let tempDate = new Date(date);
     for (let i = 0; i < days; i++) {
       tempDate.setDate(tempDate.getDate() + 1);
       if (holidays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === tempDate.getTime())) {
         tempDate.setDate(tempDate.getDate() + 1);
         i--
       }
     }
     return tempDate;
   }
   const reduceDays = async (date, days) => {
     let holidays = [];
     const listArcalendarsList = await API.graphql(
       graphqlOperation(listArcalendars, {
         filter: { IsWorkday: { eq: "0" } },
         limit: 10000,
       }))
     if (listArcalendarsList.data.listArcalendars !== null) {
       let nextToken = listArcalendarsList.data.listArcalendars.nextToken;
       holidays = listArcalendarsList.data.listArcalendars.items;
       while (nextToken !== null) {
         let InvDataSec = await API.graphql(
           graphqlOperation(listArcalendars, {
             filter: { IsWorkday: { eq: "0" } },
             limit: 10000,
             nextToken: nextToken,
           }))
         nextToken = InvDataSec.data.listArcalendars.nextToken;
         for (
           let i = 0;
           i < InvDataSec.data.listArcalendars.items.length;
           i++
         ) {
           holidays.push(InvDataSec.data.listArcalendars.items[i]);
         }
       }
     }
     let tempDate = new Date(date);
     for (let i = 0; i < days; i++) {
       tempDate.setDate(tempDate.getDate() - 1);
       if (holidays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === tempDate.getTime())) {
         tempDate.setDate(tempDate.getDate() - 1);
         i--
       }
     }
     return tempDate;
   }
   const getRegQtyAndBulkSales = async(id)=>{
    let dataAll = []
    const InvData = await API.graphql(
      graphqlOperation(queryArPriceInfosByQueryIDIndex, {
        QueryId: "P",
        limit: 10000,
        filter: {
          id: { eq: id },
          ActualFlag:{eq:"0"},
          UseGroupClassification:{eq: useGroupClassification}
        }
      })
    );
    if (InvData.data.queryArPriceInfosByQueryIDIndex !== null) {
      let nextToken = InvData.data.queryArPriceInfosByQueryIDIndex.nextToken;
      dataAll = InvData.data.queryArPriceInfosByQueryIDIndex.items;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(queryArPriceInfosByQueryIDIndex, {
            QueryId: "P",
            limit: 10000,
            nextToken: nextToken,
            filter: {
              id: { eq: id },
              ActualFlag:{eq:"0"},
              UseGroupClassification:{eq: useGroupClassification}
            }
          }
          ))
        nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
          i++
        ) {
          dataAll.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
        }
      }
    }
    return {RegQty:dataAll.length > 0 ? dataAll[0].RegQty : "",BulkSales:dataAll.length > 0 ? dataAll[0].BulkSales : "",OrdQty:dataAll.length > 0 ? dataAll[0].OrdQty : 0}
   }
   const RegQtyAndOrderQuantity = (RegQtyLe,arr,item)=>{
    RegQtyLe = [...RegQtyLe].sort(function(a, b) {return parseInt(a.RegQty) - parseInt(b.RegQty)})
    for(let j = 0;j < RegQtyLe.length;j++){
     if(item.OrderQuantity && (!(RegQtyLe[j + 1])) && (Number(item.OrderQuantity) >= parseInt(RegQtyLe[j].RegQty))){
       arr.push(RegQtyLe[j])
     }else if(item.OrderQuantity && RegQtyLe[j + 1] && (Number(item.OrderQuantity) >= parseInt(RegQtyLe[j].RegQty) && Number(item.OrderQuantity) < parseInt(RegQtyLe[j + 1].RegQty))){
       arr.push(RegQtyLe[j])
      }
    }
    return arr
   }
   const SalesUnitPriceScaleFun = async (item) => {
     let dataAll = [];
     const InvData = await API.graphql(
       graphqlOperation(queryArPriceInfosByQueryIDIndex, {
         QueryId: "P",
         limit: 10000,
         filter: {
           ItemCode: { eq: item.Product_Code },
           ActualFlag:{eq:"0"},
           UseGroupClassification:{eq: useGroupClassification}
         }
       })
     );
     if (InvData.data.queryArPriceInfosByQueryIDIndex !== null) {
       let nextToken = InvData.data.queryArPriceInfosByQueryIDIndex.nextToken;
       dataAll = InvData.data.queryArPriceInfosByQueryIDIndex.items;
       while (nextToken !== null) {
         let InvDataSec = await API.graphql(
           graphqlOperation(queryArPriceInfosByQueryIDIndex, {
             QueryId: "P",
             limit: 10000,
             nextToken: nextToken,
             filter: {
               ItemCode: { eq: item.Product_Code },
               ActualFlag:{eq:"0"},
               UseGroupClassification:{eq: useGroupClassification}
             }
           }
           ))
         nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
         for (
           let i = 0;
           i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
           i++
         ) {
           dataAll.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
         }
       }
     }
     let arr = [];
     //A
     dataAll.forEach((element) => {
       if (element.OrderReceiptCode === item.SelectContractor &&
         element.ShipmentCode === item.ShippingDestination &&
         element.EUARBmgmtCode === item.EndUserId) {
              arr.push(element)
        }
     })
      //B
       dataAll.forEach((element) => {
         if (element.OrderReceiptCode === item.SelectContractor && element.EUARBmgmtCode === item.EndUserId) {
              arr.push(element)
         }
       })
     //C
         dataAll.forEach((element) => {
           if (element.PriceTypePurchase === "EU" && element.ShipmentCode === "" && element.OrderReceiptCode === "" && element.EUARBmgmtCode === "") {
              arr.push(element)
           }
         })
      //D
         dataAll.forEach((element) => {
           if (element.PriceTypePurchase === "代" && element.ShipmentCode === "" && element.OrderReceiptCode === "" && element.EUARBmgmtCode === "") {
              arr.push(element)
           }
         })
       //E
       let type = ""
       let SalesGroupInfo = item.SalesGroupInfo
       const InvDataTransGrp = await API.graphql(
         graphqlOperation(queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex, {
           OrderReceiptCode: item.SelectContractor,
         })
       );
       if (InvDataTransGrp.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex !== null && InvDataTransGrp.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items.length > 0) {
        type = InvDataTransGrp.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items[0].AgencyType
       }
       let type5 = type
       dataAll.forEach((element) => {
        if(element.OrderReceiptCode === ""){
         if ((item.SelectContractorNm).includes("KISCO") || (item.SelectContractorNm).includes("東和電気")) {
           if (element.SuppGroup === "B10" && element.PriceTypePurchase === "B") {
              arr.push(element)
           }
         } else {
           if ((element.PriceTypePurchase === "B")) {
             if (SalesGroupInfo !== "162" && SalesGroupInfo !== "141" && SalesGroupInfo !== "181") {
               if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                  arr.push(element)
               }
             } else if (SalesGroupInfo === "162" || SalesGroupInfo === "181") {
               if ((type5 === "B10" || type5 === "B15" || type5 === "B20") && element.SuppGroup === type5) {
               if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                  arr.push(element)
               }
              }
             }else{
              if ((type5 === "B5" || type5 === "B10" || type5 === "B15" || type5 === "B20") && element.SuppGroup === type5) {
                 if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                    arr.push(element)
                 }
               }
             }
           }
         }
       }
      }
       )
      //F
       let type6 = type
       dataAll.forEach((element) => {
        if(element.OrderReceiptCode === ""){
         if ((item.SelectContractorNm).includes("KISCO") || (item.SelectContractorNm).includes("東和電気")) {
           if (element.SuppGroup === "B0" && element.PriceTypePurchase === "A") {
              arr.push(element)
           }
         } else {
           if (element.PriceTypePurchase === "A") {
             if (SalesGroupInfo !== "162" && SalesGroupInfo !== "181" && SalesGroupInfo !== "141") {
               if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                  arr.push(element)
               }
             } else if (SalesGroupInfo === "162" || SalesGroupInfo === "181") {
              if ((type6 === "B0" || type6 === "B10" || type6 === "B15" || type6 === "B20") && element.SuppGroup === type6) {
               if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                  arr.push(element)
               }}
             }else{
              if ((type6 === "B0" || type6 === "B5" || type6 === "B10" || type6 === "B15" || type6 === "B20") && element.SuppGroup === type6) {
                 if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                    arr.push(element)
                 }
               }
             }
           }
         }}
       })
       return arr
   }
   const UnitPriceScaleFun = async(arr1,item,index,ScaleDisable)=>{
    let dataAll = arr1;
    let arr = [];
    let RegQtyArr = [];
    if(ScaleDisable){
      SalesUnitPriceScaleDisable[index].Disable = false
      setSalesUnitPriceScaleDisable(SalesUnitPriceScaleDisable)
    }
     dataAll.forEach((element) => {
       if (element.OrderReceiptCode === item.SelectContractor &&
         element.ShipmentCode === item.ShippingDestination &&
         element.EUARBmgmtCode === item.EndUserId) {
          if ((element.RegQty).includes("<=")) {
            RegQtyArr.push(element)
          }else{
            arr.push(element)
          }
        }
     })
      arr = RegQtyAndOrderQuantity(RegQtyArr,arr,item)
      RegQtyArr = []
     if (arr.length > 0) {
      if(ScaleDisable){
        SalesUnitPriceScaleDisable[index].Disable = true
        setSalesUnitPriceScaleDisable(SalesUnitPriceScaleDisable)
      }
       return arr;
     } else {
       dataAll.forEach((element) => {
         if (element.OrderReceiptCode === item.SelectContractor && element.EUARBmgmtCode === item.EndUserId) {
          if ((element.RegQty).includes("<=")) {
              RegQtyArr.push(element)
            }else{
              arr.push(element)
            }
         }
       })
     }
      arr = RegQtyAndOrderQuantity(RegQtyArr,arr,item)
      RegQtyArr = []
     if (arr.length > 0) {
       return arr;
     } else {
         dataAll.forEach((element) => {
           if (element.PriceTypePurchase === "EU" && element.ShipmentCode === "" && element.OrderReceiptCode === "" && element.EUARBmgmtCode === "") {
            if ((element.RegQty).includes("<=")) {
              RegQtyArr.push(element)
            }else{
              arr.push(element)
            }
           }
         })
     }
     arr = RegQtyAndOrderQuantity(RegQtyArr,arr,item)
      RegQtyArr = []
     if (arr.length > 0) {
       return arr;
     } else {
         dataAll.forEach((element) => {
           if (element.PriceTypePurchase === "代" && element.ShipmentCode === "" && element.OrderReceiptCode === "" && element.EUARBmgmtCode === "") {
            if ((element.RegQty).includes("<=")) {
              RegQtyArr.push(element)
            }else{
              arr.push(element)
            }
           }
         })
     }
     arr = RegQtyAndOrderQuantity(RegQtyArr,arr,item)
      RegQtyArr = []
     if (arr.length > 0) {
       return arr;
     } else {
       let type5 = ""
       let SalesGroupInfo = item.SalesGroupInfo
       const InvData = await API.graphql(
         graphqlOperation(queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex, {
           OrderReceiptCode: item.SelectContractor,
         })
       );
       if (InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex !== null && InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items.length > 0) {
         type5 = InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items[0].AgencyType
       }
       dataAll.forEach((element) => {
        if(element.OrderReceiptCode === ""){
         if ((item.SelectContractorNm).includes("KISCO") || (item.SelectContractorNm).includes("東和電気")) {
           if (element.SuppGroup === "B10" && element.PriceTypePurchase === "B") {
            if ((element.RegQty).includes("<=")) {
              RegQtyArr.push(element)
            }else{
              arr.push(element)
            }
           }
         } else {
           if ((element.PriceTypePurchase === "B")) {
             if (SalesGroupInfo !== "162" && SalesGroupInfo !== "181" && SalesGroupInfo !== "141") {
               if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                if ((element.RegQty).includes("<=")) {
                  RegQtyArr.push(element)
                }else{
                  arr.push(element)
                }
               }
             } else if (SalesGroupInfo === "162" || SalesGroupInfo === "181") {
               if ((type5 === "B10" || type5 === "B15" || type5 === "B20") && element.SuppGroup === type5) {
                 if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                  if ((element.RegQty).includes("<=")) {
                    RegQtyArr.push(element)
                  }else{
                    arr.push(element)
                  }
                 }
               }
             }else{
              if ((type5 === "B5" || type5 === "B10" || type5 === "B15" || type5 === "B20") && element.SuppGroup === type5) {
                 if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                  if ((element.RegQty).includes("<=")) {
                    RegQtyArr.push(element)
                  }else{
                    arr.push(element)
                  }
                 }
               }
             }
           }
         }
       }
      }
       )
     }
     arr = RegQtyAndOrderQuantity(RegQtyArr,arr,item)
      RegQtyArr = []
     if (arr.length > 0) {
       return arr;
     } else {
       let type6 = ""
       let SalesGroupInfo = item.SalesGroupInfo
       const InvData = await API.graphql(
         graphqlOperation(queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex, {
           OrderReceiptCode: item.SelectContractor,
         })
       );
       if (InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex !== null && InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items.length > 0) {
         type6 = InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items[0].AgencyType
       }
       dataAll.forEach((element) => {
        if(element.OrderReceiptCode === ""){
         if ((item.SelectContractorNm).includes("KISCO") || (item.SelectContractorNm).includes("東和電気")) {
           if (element.SuppGroup === "B0" && element.PriceTypePurchase === "A") {
            if ((element.RegQty).includes("<=")) {
              RegQtyArr.push(element)
            }else{
              arr.push(element)
            }
           }
         } else {
           if (element.PriceTypePurchase === "A") {
             if (SalesGroupInfo !== "162" && SalesGroupInfo !== "181" && SalesGroupInfo !== "141") {
               if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                if ((element.RegQty).includes("<=")) {
                  RegQtyArr.push(element)
                }else{
                  arr.push(element)
                }
               }
             } else if (SalesGroupInfo === "162" || SalesGroupInfo === "181") {
              if ((type6 === "B0" || type6 === "B10" || type6 === "B15" || type6 === "B20") && element.SuppGroup === type6) {
                 if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                  if ((element.RegQty).includes("<=")) {
                    RegQtyArr.push(element)
                  }else{
                    arr.push(element)
                  }
                 }
               }
             }else{
              if ((type6 === "B0" || type6 === "B5" || type6 === "B10" || type6 === "B15" || type6 === "B20") && element.SuppGroup === type6) {
                 if (element.OrderReceiptCode === item.SelectContractor || element.OrderReceiptCode === "") {
                  if ((element.RegQty).includes("<=")) {
                    RegQtyArr.push(element)
                  }else{
                    arr.push(element)
                  }
                 }
               }
             }
           }
         }}
       })
      arr = RegQtyAndOrderQuantity(RegQtyArr,arr,item)
      RegQtyArr = []
       return arr
     }
   }
   function isValidNumber(str) {
    const regex = /^[0-9]*\.?[0-9]+$/;
    return regex.test(str);
  }
  function calculatePrice(orderQuantity, unit1, unit2, unit3, unit4, unit5, price1, price2, price3, price4, price5) {
    if (orderQuantity < unit1 || (orderQuantity >= unit1 && (unit2 === "" || (!unit2))) || (orderQuantity >= unit1 && orderQuantity < unit2)) {
        return price1;
    } else if ((orderQuantity >= unit2 && (unit3 === "" || (!unit3))) || (orderQuantity >= unit2 && orderQuantity < unit3)) {
        return price2 || price1;
    } else if ((orderQuantity >= unit3 && (unit4 === "" || (!unit4))) || (orderQuantity >= unit3 && orderQuantity < unit4)) {
        return price3 || price2 || price1;
    } else if ((orderQuantity >= unit4 && (unit5 === "" || (!unit5))) || (orderQuantity >= unit4 && orderQuantity < unit5)) {
        return price4 || price3 || price2 || price1;
    } else {
        return price5 || price4 || price3 || price2 || price1;
    }
}
   const SalespriceInfo = async (item, arr,index) => {
     let Salesprice = ""
     let SalesUnitPriceObj = {}
     let UnitPriceArr = []
     let ExpiredArr = []
     //let resultArr = []
     for (let ele = 0; ele < arr.length; ele++) {
       if (arr[ele].Expired) {
         if (arr[ele].RecordSalesApplicable === "出荷日") {
           let leadTime = 0
           if (arr[ele].ShipmentCode) {
             let listCustomerAddressesInfo = await API.graphql(
               graphqlOperation(queryArCustomerAddressesByCustomerReceiptCodeIndex, {
                 CustomerReceiptCode: arr[ele].ShipmentCode,
               })
             );
             if (listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex !== null) {
               let CustomerAddressesInfo = listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex.items;
               //福井西濃LT
               if (CustomerAddressesInfo.length > 0 && CustomerAddressesInfo[0].PostCode) {
                 leadTime = await FukuiSeinoLTFun(CustomerAddressesInfo[0].PostCode)
               }
             }
           }
           let shipDate = await reduceDays((moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
            if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD'))) {
              ExpiredArr.push(arr[ele])
              // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD'))) {
              //   resultArr.push(arr[ele])
              // }
           }
         } else if (arr[ele].RecordSalesApplicable === "納品日") {
           if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD'))) {
            ExpiredArr.push(arr[ele])
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD'))) {
            //   resultArr.push(arr[ele])
            // }
           }
         } else if (arr[ele].RecordSalesApplicable === "受注日") {
          if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && (moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD'))) {
            ExpiredArr.push(arr[ele])
          //   if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && (moment(new Date()).utcOffset(9).format('YYYY/MM/DD') <= moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD'))) {
          //     resultArr.push(arr[ele])
          //     }
           }
         }
       } else {
         if (arr[ele].RecordSalesApplicable === "出荷日") {
           let leadTime = 0
           if (arr[ele].ShipmentCode) {
             let listCustomerAddressesInfo = await API.graphql(
               graphqlOperation(queryArCustomerAddressesByCustomerReceiptCodeIndex, {
                 CustomerReceiptCode: arr[ele].ShipmentCode,
               })
             );
             if (listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex !== null) {
               let CustomerAddressesInfo = listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex.items;
               //福井西濃LT
               if (CustomerAddressesInfo.length > 0 && CustomerAddressesInfo[0].PostCode) {
                 leadTime = await FukuiSeinoLTFun(CustomerAddressesInfo[0].PostCode)
               }
             }
           }
           let shipDate = await reduceDays((moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
           if ((moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
            UnitPriceArr.push(arr[ele])
           }
         } else if (arr[ele].RecordSalesApplicable === "納品日") {
          if ((moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD'))) {
            UnitPriceArr.push(arr[ele])
           }
         } else if (arr[ele].RecordSalesApplicable === "受注日") {
           Date
           if ((moment(arr[ele].RecordSalesDate).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
            UnitPriceArr.push(arr[ele])
           }
         }
       }
     }
    //  if(ExpiredArr.length > 1){
    //   UnitPriceArr.push(...resultArr)
    //  }else{
      UnitPriceArr.push(...ExpiredArr)
    //  }
     let arr1 = await UnitPriceScaleFun(UnitPriceArr,item,index,true)
     if (arr1.length > 0) {
      let obj = {}
      //卸売価格
      if(arr1.length > 1){
        let arr2 = arr1.filter((a)=>{
          if((a.RegQty).includes("<=")){
            if(Number(item.OrderQuantity) >= parseInt(a.RegQty)){
              return true
            }}})
        let arr3 = arr1.filter((a)=>{
          if(!((a.RegQty).includes("<="))){
          return true}})
        if(arr2.length > 0){
            if (arr2.length > 1) {
                if (item.BulkSales === "0") {
                  let arr = []
                  for(let i = 0;i < arr2.length;i++){
                    switch (String(item.OrderQuantity)) {
                      case arr2[i].SalesUnitPriceUnitScale1: {
                        arr.push({SalesUnitPriceObj:arr2[i],price:arr2[i].SalesUnitPriceScale1});break;
                      }
                      case arr2[i].SalesUnitPriceUnitScale2: {
                        arr.push({SalesUnitPriceObj:arr2[i],price:arr2[i].SalesUnitPriceScale2 || arr2[i].SalesUnitPriceScale1}); break;
                      }
                      case arr2[i].SalesUnitPriceUnitScale3: {
                        arr.push({SalesUnitPriceObj:arr2[i],price:arr2[i].SalesUnitPriceScale3 || arr2[i].SalesUnitPriceScale2 || arr2[i].SalesUnitPriceScale1}); break;
                      }
                      case arr2[i].SalesUnitPriceUnitScale4: {
                        arr.push({SalesUnitPriceObj:arr2[i],price:arr2[i].SalesUnitPriceScale4 || arr2[i].SalesUnitPriceScale3 || arr2[i].SalesUnitPriceScale2 || arr2[i].SalesUnitPriceScale1}); break;
                      }
                      case arr2[i].SalesUnitPriceUnitScale5: {
                        arr.push({SalesUnitPriceObj:arr2[i],price:arr2[i].SalesUnitPriceScale5 || arr2[i].SalesUnitPriceScale4 || arr2[i].SalesUnitPriceScale3 || arr2[i].SalesUnitPriceScale2 || arr2[i].SalesUnitPriceScale1}); break;
                      }
                      default: {
                        arr.push({SalesUnitPriceObj:arr2[i],price:calculatePrice(Number(item.OrderQuantity),Number(arr2[i].SalesUnitPriceUnitScale1),Number(arr2[i].SalesUnitPriceUnitScale2),Number(arr2[i].SalesUnitPriceUnitScale3),Number(arr2[i].SalesUnitPriceUnitScale4),Number(arr2[i].SalesUnitPriceUnitScale5),arr2[i].SalesUnitPriceScale1,arr2[i].SalesUnitPriceScale2,arr2[i].SalesUnitPriceScale3,arr2[i].SalesUnitPriceScale4,arr2[i].SalesUnitPriceScale5)}); break;
                      }
                    }
                  }
                  arr = [...arr].sort(function(a, b) {return a.price - b.price})
                  Salesprice = arr[0].price
                  SalesUnitPriceObj = arr[0].SalesUnitPriceObj
                } else {
                  let arr = []
                  for(let i = 0;i < arr2.length;i++){
                    arr.push({SalesUnitPriceObj:arr2[i],price:calculatePrice(Number(item.OrderQuantity),Number(arr2[i].SalesUnitPriceUnitScale1),Number(arr2[i].SalesUnitPriceUnitScale2),Number(arr2[i].SalesUnitPriceUnitScale3),Number(arr2[i].SalesUnitPriceUnitScale4),Number(arr2[i].SalesUnitPriceUnitScale5),arr2[i].SalesUnitPriceScale1,arr2[i].SalesUnitPriceScale2,arr2[i].SalesUnitPriceScale3,arr2[i].SalesUnitPriceScale4,arr2[i].SalesUnitPriceScale5)})
                  }
                  arr = [...arr].sort(function(a, b) {return a.price - b.price})
                  Salesprice = arr[0].price
                  SalesUnitPriceObj = arr[0].SalesUnitPriceObj
                }
          } else {
            obj = arr2[0]
            SalesUnitPriceObj = arr2[0]
            if (item.BulkSales === "0") {
              switch (String(item.OrderQuantity)) {
                case obj.SalesUnitPriceUnitScale1: Salesprice = obj.SalesUnitPriceScale1; break;
                case obj.SalesUnitPriceUnitScale2: Salesprice = obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
                case obj.SalesUnitPriceUnitScale3: Salesprice = obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
                case obj.SalesUnitPriceUnitScale4: Salesprice = obj.SalesUnitPriceScale4 || obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
                case obj.SalesUnitPriceUnitScale5: Salesprice = obj.SalesUnitPriceScale5 || obj.SalesUnitPriceScale4 || obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
                default: Salesprice = calculatePrice(Number(item.OrderQuantity),Number(obj.SalesUnitPriceUnitScale1),Number(obj.SalesUnitPriceUnitScale2),Number(obj.SalesUnitPriceUnitScale3),Number(obj.SalesUnitPriceUnitScale4),Number(obj.SalesUnitPriceUnitScale5),obj.SalesUnitPriceScale1,obj.SalesUnitPriceScale2,obj.SalesUnitPriceScale3,obj.SalesUnitPriceScale4,obj.SalesUnitPriceScale5); break;
              }
            } else {
              Salesprice = calculatePrice(Number(item.OrderQuantity),Number(obj.SalesUnitPriceUnitScale1),Number(obj.SalesUnitPriceUnitScale2),Number(obj.SalesUnitPriceUnitScale3),Number(obj.SalesUnitPriceUnitScale4),Number(obj.SalesUnitPriceUnitScale5),obj.SalesUnitPriceScale1,obj.SalesUnitPriceScale2,obj.SalesUnitPriceScale3,obj.SalesUnitPriceScale4,obj.SalesUnitPriceScale5)
             }
          }
        }else{
          if (arr3.length > 1) {
            if (item.BulkSales === "0") {
              let arr = []
              for(let i = 0;i < arr3.length;i++){
                switch (String(item.OrderQuantity)) {
                  case arr3[i].SalesUnitPriceUnitScale1: {
                    arr.push({SalesUnitPriceObj:arr3[i],price:arr3[i].SalesUnitPriceScale1});break;
                  }
                  case arr3[i].SalesUnitPriceUnitScale2: {
                    arr.push({SalesUnitPriceObj:arr3[i],price:arr3[i].SalesUnitPriceScale2 || arr3[i].SalesUnitPriceScale1}); break;
                  }
                  case arr3[i].SalesUnitPriceUnitScale3: {
                    arr.push({SalesUnitPriceObj:arr3[i],price:arr3[i].SalesUnitPriceScale3 || arr3[i].SalesUnitPriceScale2 || arr3[i].SalesUnitPriceScale1}); break;
                  }
                  case arr3[i].SalesUnitPriceUnitScale4: {
                    arr.push({SalesUnitPriceObj:arr3[i],price:arr3[i].SalesUnitPriceScale4 || arr3[i].SalesUnitPriceScale3 || arr3[i].SalesUnitPriceScale2 || arr3[i].SalesUnitPriceScale1}); break;
                  }
                  case arr3[i].SalesUnitPriceUnitScale5: {
                    arr.push({SalesUnitPriceObj:arr3[i],price:arr3[i].SalesUnitPriceScale5 || arr3[i].SalesUnitPriceScale4 || arr3[i].SalesUnitPriceScale3 || arr3[i].SalesUnitPriceScale2 || arr3[i].SalesUnitPriceScale1}); break;
                  }
                  default: {
                    arr.push({SalesUnitPriceObj:arr3[i],price:calculatePrice(Number(item.OrderQuantity),Number(arr3[i].SalesUnitPriceUnitScale1),Number(arr3[i].SalesUnitPriceUnitScale2),Number(arr3[i].SalesUnitPriceUnitScale3),Number(arr3[i].SalesUnitPriceUnitScale4),Number(arr3[i].SalesUnitPriceUnitScale5),arr3[i].SalesUnitPriceScale1,arr3[i].SalesUnitPriceScale2,arr3[i].SalesUnitPriceScale3,arr3[i].SalesUnitPriceScale4,arr3[i].SalesUnitPriceScale5)}); break;
                  }
                }
              }
              arr = [...arr].sort(function(a, b) {return a.price - b.price})
              Salesprice = arr[0].price
              SalesUnitPriceObj = arr[0].SalesUnitPriceObj
            } else {
              let arr = []
              for(let i = 0;i < arr3.length;i++){
                arr.push({SalesUnitPriceObj:arr3[i],price:calculatePrice(Number(item.OrderQuantity),Number(arr3[i].SalesUnitPriceUnitScale1),Number(arr3[i].SalesUnitPriceUnitScale2),Number(arr3[i].SalesUnitPriceUnitScale3),Number(arr3[i].SalesUnitPriceUnitScale4),Number(arr3[i].SalesUnitPriceUnitScale5),arr3[i].SalesUnitPriceScale1,arr3[i].SalesUnitPriceScale2,arr3[i].SalesUnitPriceScale3,arr3[i].SalesUnitPriceScale4,arr3[i].SalesUnitPriceScale5)})
              }
              arr = [...arr].sort(function(a, b) {return a.price - b.price})
              Salesprice = arr[0].price
              SalesUnitPriceObj = arr[0].SalesUnitPriceObj
            }
      } else {
        obj = arr3.length > 0 && arr3[0]
        SalesUnitPriceObj = arr3.length > 0 && arr3[0]
        if (item.BulkSales === "0") {
          switch (String(item.OrderQuantity)) {
            case obj.SalesUnitPriceUnitScale1: Salesprice = obj.SalesUnitPriceScale1; break;
            case obj.SalesUnitPriceUnitScale2: Salesprice = obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
            case obj.SalesUnitPriceUnitScale3: Salesprice = obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
            case obj.SalesUnitPriceUnitScale4: Salesprice = obj.SalesUnitPriceScale4 || obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
            case obj.SalesUnitPriceUnitScale5: Salesprice = obj.SalesUnitPriceScale5 || obj.SalesUnitPriceScale4 || obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
            default: Salesprice = calculatePrice(Number(item.OrderQuantity),Number(obj.SalesUnitPriceUnitScale1),Number(obj.SalesUnitPriceUnitScale2),Number(obj.SalesUnitPriceUnitScale3),Number(obj.SalesUnitPriceUnitScale4),Number(obj.SalesUnitPriceUnitScale5),obj.SalesUnitPriceScale1,obj.SalesUnitPriceScale2,obj.SalesUnitPriceScale3,obj.SalesUnitPriceScale4,obj.SalesUnitPriceScale5); break;
          }
        } else {
          Salesprice = calculatePrice(Number(item.OrderQuantity),Number(obj.SalesUnitPriceUnitScale1),Number(obj.SalesUnitPriceUnitScale2),Number(obj.SalesUnitPriceUnitScale3),Number(obj.SalesUnitPriceUnitScale4),Number(obj.SalesUnitPriceUnitScale5),obj.SalesUnitPriceScale1,obj.SalesUnitPriceScale2,obj.SalesUnitPriceScale3,obj.SalesUnitPriceScale4,obj.SalesUnitPriceScale5)
         }
      }
        }
      }else{
        obj = arr1[0]
        SalesUnitPriceObj = arr1[0]
        if (item.BulkSales === "0") {
          switch (String(item.OrderQuantity)) {
            case obj.SalesUnitPriceUnitScale1: Salesprice = obj.SalesUnitPriceScale1; break;
            case obj.SalesUnitPriceUnitScale2: Salesprice = obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
            case obj.SalesUnitPriceUnitScale3: Salesprice = obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
            case obj.SalesUnitPriceUnitScale4: Salesprice = obj.SalesUnitPriceScale4 || obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
            case obj.SalesUnitPriceUnitScale5: Salesprice = obj.SalesUnitPriceScale5 || obj.SalesUnitPriceScale4 || obj.SalesUnitPriceScale3 || obj.SalesUnitPriceScale2 || obj.SalesUnitPriceScale1; break;
            default: Salesprice = calculatePrice(Number(item.OrderQuantity),Number(obj.SalesUnitPriceUnitScale1),Number(obj.SalesUnitPriceUnitScale2),Number(obj.SalesUnitPriceUnitScale3),Number(obj.SalesUnitPriceUnitScale4),Number(obj.SalesUnitPriceUnitScale5),obj.SalesUnitPriceScale1,obj.SalesUnitPriceScale2,obj.SalesUnitPriceScale3,obj.SalesUnitPriceScale4,obj.SalesUnitPriceScale5); break;
          }
        } else {
         Salesprice = calculatePrice(Number(item.OrderQuantity),Number(obj.SalesUnitPriceUnitScale1),Number(obj.SalesUnitPriceUnitScale2),Number(obj.SalesUnitPriceUnitScale3),Number(obj.SalesUnitPriceUnitScale4),Number(obj.SalesUnitPriceUnitScale5),obj.SalesUnitPriceScale1,obj.SalesUnitPriceScale2,obj.SalesUnitPriceScale3,obj.SalesUnitPriceScale4,obj.SalesUnitPriceScale5)
        }
      }
     }
     return {SalesUnitPriceObj:SalesUnitPriceObj,price:isValidNumber(moneytonum(Salesprice)) === false ? "" : Salesprice}
   }
   //仕入単価計算
   const PurchpriceInfo = async (item, arr,index) => {
     let Purchprice = ""
     let UnitPriceArr = []
     let ExpiredArr = []
     //let resultArr = []
     for (let ele = 0; ele < arr.length; ele++) {
       if (arr[ele].Expired) {
         if (arr[ele].AcctForPurchasesApplicableble === "出荷日") {
           let leadTime = 0
           if (arr[ele].ShipmentCode) {
             let listCustomerAddressesInfo = await API.graphql(
               graphqlOperation(queryArCustomerAddressesByCustomerReceiptCodeIndex, {
                 CustomerReceiptCode: arr[ele].ShipmentCode,
               })
             );
             if (listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex !== null) {
               let CustomerAddressesInfo = listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex.items;
               //福井西濃LT
               if (CustomerAddressesInfo.length > 0 && CustomerAddressesInfo[0].PostCode) {
                 leadTime = await FukuiSeinoLTFun(CustomerAddressesInfo[0].PostCode)
               }
             }
           }
           let shipDate = await reduceDays((moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
           if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) >= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) {
            ExpiredArr.push(arr[ele])
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) && (moment(shipDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
            //   resultArr.push(arr[ele])
            // }
           }
         } else if (arr[ele].AcctForPurchasesApplicableble === "納品日") {
           if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
            ExpiredArr.push(arr[ele])
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) && (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')) <= (moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
            //   resultArr.push(arr[ele])
            // }
           }
         } else if (arr[ele].AcctForPurchasesApplicableble === "受注日") {
           if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD') >= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
            ExpiredArr.push(arr[ele])
            // if ((moment(arr[ele].ExpirationStart).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD') && (moment(new Date()).utcOffset(9).format('YYYY/MM/DD') <= moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD'))) {
            //   resultArr.push(arr[ele])
            //   }
           }
         }
       } else {
         if (arr[ele].AcctForPurchasesApplicableble === "出荷日") {
           let leadTime = 0
           if (arr[ele].ShipmentCode) {
             let listCustomerAddressesInfo = await API.graphql(
               graphqlOperation(queryArCustomerAddressesByCustomerReceiptCodeIndex, {
                 CustomerReceiptCode: arr[ele].ShipmentCode,
               })
             );
             if (listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex !== null) {
               let CustomerAddressesInfo = listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex.items;
               //福井西濃LT
               if (CustomerAddressesInfo.length > 0 && CustomerAddressesInfo[0].PostCode) {
                 leadTime = await FukuiSeinoLTFun(CustomerAddressesInfo[0].PostCode)
               }
             }
           }
           let shipDate = await reduceDays((moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD')), parseInt(leadTime))
           if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(shipDate).utcOffset(9).format('YYYY/MM/DD'))) { UnitPriceArr.push(arr[ele]) }
         } else if (arr[ele].AcctForPurchasesApplicableble === "納品日") {
           if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= (moment(item.DesiredDeliveryDateCopy).utcOffset(9).format('YYYY/MM/DD'))) {
            UnitPriceArr.push(arr[ele])
           }
         } else if (arr[ele].AcctForPurchasesApplicableble === "受注日") {
           if ((moment(arr[ele].PurchaseAccountDate).utcOffset(9).format('YYYY/MM/DD')) <= moment(new Date()).utcOffset(9).format('YYYY/MM/DD')) {
            UnitPriceArr.push(arr[ele])
           }
         }
       }
     }
     //if(ExpiredArr.length > 1){
     //UnitPriceArr.push(...resultArr)
     //}else{
      UnitPriceArr.push(...ExpiredArr)
     //}
     let arr1 = await UnitPriceScaleFun(UnitPriceArr,item,index,false)
     let obj = {}
     if (arr1.length > 0) {
      //卸売価格
      if(arr1.length > 1){
        let arr2 = arr1.filter((a)=>{
          if((a.RegQty).includes("<=")){
            if(Number(item.OrderQuantity) >= parseInt(a.RegQty)){
              return true
            }}})
        let arr3 = arr1.filter((a)=>{
          if(!((a.RegQty).includes("<="))){
          return true}})
        if(arr2.length > 0){
          if (arr2.length > 1) {
                if (item.BulkSales === "0") {
                  let arr = []
                  for(let i = 0;i < arr2.length;i++){
                    switch (String(item.OrderQuantity)) {
                      case arr2[i].PurchUnitPriceUnitScale1: {
                        arr.push(arr2[i].PurchUnitPriceScale1);break;
                      }
                      case arr2[i].PurchUnitPriceUnitScale2: {
                        arr.push(arr2[i].PurchUnitPriceScale2 || arr2[i].PurchUnitPriceScale1); break;
                      }
                      case arr2[i].PurchUnitPriceUnitScale3: {
                        arr.push(arr2[i].PurchUnitPriceScale3 || arr2[i].PurchUnitPriceScale3 || arr2[i].PurchUnitPriceScale1); break;
                      }
                      case arr2[i].PurchUnitPriceUnitScale4: {
                        arr.push(arr2[i].PurchUnitPriceScale4 || arr2[i].PurchUnitPriceScale3 || arr2[i].PurchUnitPriceScale2 || arr2[i].PurchUnitPriceScale1); break;
                      }
                      case arr2[i].PurchUnitPriceUnitScale5: {
                        arr.push(arr2[i].PurchUnitPriceScale5 || arr2[i].PurchUnitPriceScale4 || arr2[i].PurchUnitPriceScale3 || arr2[i].PurchUnitPriceScale2 || arr2[i].PurchUnitPriceScale1); break;
                      }
                      default: {
                        arr.push(calculatePrice(Number(item.OrderQuantity),Number(arr2[i].PurchUnitPriceUnitScale1),Number(arr2[i].PurchUnitPriceUnitScale2),Number(arr2[i].PurchUnitPriceUnitScale3),Number(arr2[i].PurchUnitPriceUnitScale4),Number(arr2[i].PurchUnitPriceUnitScale5),arr2[i].PurchUnitPriceScale1,arr2[i].PurchUnitPriceScale2,arr2[i].PurchUnitPriceScale3,arr2[i].PurchUnitPriceScale4,arr2[i].PurchUnitPriceScale5)); break;
                      }
                    }
                  }
                  arr = [...arr].sort(function(a, b) {return a - b})
                  Purchprice = arr[0]
                } else {
                  let arr = []
                  for(let i = 0;i < arr2.length;i++){
                    arr.push(calculatePrice(Number(item.OrderQuantity),Number(arr2[i].PurchUnitPriceUnitScale1),Number(arr2[i].PurchUnitPriceUnitScale2),Number(arr2[i].PurchUnitPriceUnitScale3),Number(arr2[i].PurchUnitPriceUnitScale4),Number(arr2[i].PurchUnitPriceUnitScale5),arr2[i].PurchUnitPriceScale1,arr2[i].PurchUnitPriceScale2,arr2[i].PurchUnitPriceScale3,arr2[i].PurchUnitPriceScale4,arr2[i].PurchUnitPriceScale5))
                  }
                  arr = [...arr].sort(function(a, b) {return a - b})
                  Purchprice = arr[0]
                }
          } else {
            obj = arr2[0]
            if (item.BulkSales === "0") {
              switch (String(item.OrderQuantity)) {
                case obj.PurchUnitPriceUnitScale1: Purchprice = obj.PurchUnitPriceScale1; break;
                case obj.PurchUnitPriceUnitScale2: Purchprice = obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                case obj.PurchUnitPriceUnitScale3: Purchprice = obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                case obj.PurchUnitPriceUnitScale4: Purchprice = obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                case obj.PurchUnitPriceUnitScale5: Purchprice = obj.PurchUnitPriceScale5 || obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                default: Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5); break;
              }
            } else {
             Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5)
            }
          }
        }else{
          if (arr3.length > 1) {
            if (item.BulkSales === "0") {
              let arr = []
                  for(let i = 0;i < arr3.length;i++){
                    switch (String(item.OrderQuantity)) {
                      case arr3[i].PurchUnitPriceUnitScale1: {
                        arr.push(arr3[i].PurchUnitPriceScale1);break;
                      }
                      case arr3[i].PurchUnitPriceUnitScale2: {
                        arr.push(arr3[i].PurchUnitPriceScale2 || arr3[i].PurchUnitPriceScale1); break;
                      }
                      case arr3[i].PurchUnitPriceUnitScale3: {
                        arr.push(arr3[i].PurchUnitPriceScale3 || arr3[i].PurchUnitPriceScale3 || arr3[i].PurchUnitPriceScale1); break;
                      }
                      case arr3[i].PurchUnitPriceUnitScale4: {
                        arr.push(arr3[i].PurchUnitPriceScale4 || arr3[i].PurchUnitPriceScale3 || arr3[i].PurchUnitPriceScale2 || arr3[i].PurchUnitPriceScale1); break;
                      }
                      case arr3[i].PurchUnitPriceUnitScale5: {
                        arr.push(arr3[i].PurchUnitPriceScale5 || arr3[i].PurchUnitPriceScale4 || arr3[i].PurchUnitPriceScale3 || arr3[i].PurchUnitPriceScale2 || arr3[i].PurchUnitPriceScale1); break;
                      }
                      default: {
                        arr.push(calculatePrice(Number(item.OrderQuantity),Number(arr3[i].PurchUnitPriceUnitScale1),Number(arr3[i].PurchUnitPriceUnitScale2),Number(arr3[i].PurchUnitPriceUnitScale3),Number(arr3[i].PurchUnitPriceUnitScale4),Number(arr3[i].PurchUnitPriceUnitScale5),arr3[i].PurchUnitPriceScale1,arr3[i].PurchUnitPriceScale2,arr3[i].PurchUnitPriceScale3,arr3[i].PurchUnitPriceScale4,arr3[i].PurchUnitPriceScale5)); break;
                      }
                    }
                  }
                  arr = [...arr].sort(function(a, b) {return a - b})
                  Purchprice = arr[0]
        } else {
          let arr = []
          for(let i = 0;i < arr3.length;i++){
            arr.push(calculatePrice(Number(item.OrderQuantity),Number(arr3[i].PurchUnitPriceUnitScale1),Number(arr3[i].PurchUnitPriceUnitScale2),Number(arr3[i].PurchUnitPriceUnitScale3),Number(arr3[i].PurchUnitPriceUnitScale4),Number(arr3[i].PurchUnitPriceUnitScale5),arr3[i].PurchUnitPriceScale1,arr3[i].PurchUnitPriceScale2,arr3[i].PurchUnitPriceScale3,arr3[i].PurchUnitPriceScale4,arr3[i].PurchUnitPriceScale5))
          }
          arr = [...arr].sort(function(a, b) {return a - b})
          Purchprice = arr[0]
        }
          } else {
              obj = arr3.length > 0 && arr3[0]
              if (item.BulkSales === "0") {
                switch (String(item.OrderQuantity)) {
                  case obj.PurchUnitPriceUnitScale1: Purchprice = obj.PurchUnitPriceScale1; break;
                  case obj.PurchUnitPriceUnitScale2: Purchprice = obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                  case obj.PurchUnitPriceUnitScale3: Purchprice = obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                  case obj.PurchUnitPriceUnitScale4: Purchprice = obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                  case obj.PurchUnitPriceUnitScale5: Purchprice = obj.PurchUnitPriceScale5 || obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
                  default: Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5); break;
                }
              } else {
                Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5)
               }
          }
        }
      }else{
        obj = arr1[0]
        if (item.BulkSales === "0") {
          switch (String(item.OrderQuantity)) {
            case obj.PurchUnitPriceUnitScale1: Purchprice = obj.PurchUnitPriceScale1; break;
            case obj.PurchUnitPriceUnitScale2: Purchprice = obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
            case obj.PurchUnitPriceUnitScale3: Purchprice = obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
            case obj.PurchUnitPriceUnitScale4: Purchprice = obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
            case obj.PurchUnitPriceUnitScale5: Purchprice = obj.PurchUnitPriceScale5 || obj.PurchUnitPriceScale4 || obj.PurchUnitPriceScale3 || obj.PurchUnitPriceScale2 || obj.PurchUnitPriceScale1; break;
            default: Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5); break;
          }
        } else {
          Purchprice = calculatePrice(Number(item.OrderQuantity),Number(obj.PurchUnitPriceUnitScale1),Number(obj.PurchUnitPriceUnitScale2),Number(obj.PurchUnitPriceUnitScale3),Number(obj.PurchUnitPriceUnitScale4),Number(obj.PurchUnitPriceUnitScale5),obj.PurchUnitPriceScale1,obj.PurchUnitPriceScale2,obj.PurchUnitPriceScale3,obj.PurchUnitPriceScale4,obj.PurchUnitPriceScale5)
         }
      }
     }
     return isValidNumber(moneytonum(Purchprice)) === false ? "" : Purchprice
   }
   function moneytonum(val) {
     let num = val ? val.trim() : ""
     let ss = num.toString();
     if (ss.length === 0) {
       return "0";
     }
     return ss.replace(/,/g, "");
   }
   function numtomoney(number, toFixed) {
     let str = [];
     if (!number) return 0;
     if (typeof number === "number") number = String(number);
     let tempNumber = number.split(".");
     number = tempNumber[0];
     if (number.length <= 3) {
       if (tempNumber[1] && toFixed !== 0) {
         number += "." + tempNumber[1].slice(0, toFixed);
       }
       return number;
     }
     number
       .split("")
       .reverse()
       .forEach((item, index) => {
         if (index !== 0 && index % 3 === 0) {
           str.push(",", item);
         } else {
           str.push(item);
         }
       });
     number = str.reverse().join("");
     if (tempNumber[1] && toFixed !== 0) {
       number += "." + tempNumber[1].slice(0, toFixed);
     }
     return number;
   }
   function convertToKGPerUnit(value) {
     let num = parseFloat(value.split(":")[0]);
     if (num === "") {
       return 0;
     }
     let unit = value.split(":")[1];
     if (unit === 'KG' || unit === 'kg') {
       return num;
     } else if (unit === 'G' || unit === 'g') {
       return num / 1000;
     } else {
       return ""
     }
   }
   const [text, setText] = useState('');
   const handleInputChange = (value) => {
     setText(value);
   };
   const [mailtoLink, setMailtoLink] = useState()
   const handleCopyClick = () => {
    setSearchProgress(true)
     navigator.clipboard.writeText(msgTextmail)
       .then(() => {
         console.log('Text copied to clipboard:', text);
       })
       .catch((error) => {
         console.error('Copy to clipboard failed:', error);
       });
     handleCloseOKmail();
     window.location.href = mailtoLink;
     updateQuote_Reg_flag();
     setSearchProgress(false)
   };
   const createQuoteNumber = () => {
     let format = "";
     let nTime = new Date();
     format += nTime.getFullYear().toString().substring(2);
     format +=
       nTime.getMonth() + 1 < 10
         ? "0" + (nTime.getMonth() + 1)
         : nTime.getMonth() + 1;
     format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
     format += nTime.getHours() < 10 ? "0" + nTime.getHours() : nTime.getHours();
     format +=
       nTime.getMinutes() < 10 ? "0" + nTime.getMinutes() : nTime.getMinutes();
     format +=
       nTime.getSeconds() < 10 ? "0" + nTime.getSeconds() : nTime.getSeconds();
     if (nTime.getMilliseconds() < 10) {
       format += "00" + nTime.getMilliseconds();
     } else if (nTime.getMilliseconds() < 100) {
       format += "0" + nTime.getMilliseconds();
     } else {
       format += nTime.getMilliseconds();
     }
     format += Math.floor(Math.random() * 88) + 10;
     let QuoteNumber = "P" + format;
     return QuoteNumber
   }
   const createOrderDetailsNumber = () => {
     let format = "";
     let nTime = new Date();
     format += nTime.getFullYear().toString().substring(2);
     format +=
       nTime.getMonth() + 1 < 10
         ? "0" + (nTime.getMonth() + 1)
         : nTime.getMonth() + 1;
     format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
     format += nTime.getHours() < 10 ? "0" + nTime.getHours() : nTime.getHours();
     format +=
       nTime.getMinutes() < 10 ? "0" + nTime.getMinutes() : nTime.getMinutes();
     format +=
       nTime.getSeconds() < 10 ? "0" + nTime.getSeconds() : nTime.getSeconds();
     if (nTime.getMilliseconds() < 10) {
       format += "00" + nTime.getMilliseconds();
     } else if (nTime.getMilliseconds() < 100) {
       format += "0" + nTime.getMilliseconds();
     } else {
       format += nTime.getMilliseconds();
     }
     format += Math.floor(Math.random() * 88) + 10;
     let OrderDetailsNumber = "E" + format;
     return OrderDetailsNumber;
   }
   const selectedDateInit = async()=>{
    let IsWorkdays = await getIsWorkday()
    let tempDate = new Date();
for (let i = 0; i < 3; i++) {
  tempDate.setDate(tempDate.getDate() + 1);
  if (IsWorkdays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
    tempDate.setDate(tempDate.getDate() + 1);
    i--
  }
}
return moment(tempDate).utcOffset(9).format('YYYY-MM-DD')
   }
   const Repurchase = async () => {
    setSearchProgress(true)
    RepurchaseDisableInfo = true
     let RepurchaseQuoteNumber = createQuoteNumber()
     for (let j = 0; j < orderinfo.length; j++) {
      try {
       orderinfo[j].id = uuidv4();
       orderinfo[j].QuoteNumber = RepurchaseQuoteNumber;
       orderinfo[j].OrderDetailsNumber = createOrderDetailsNumber()
       orderinfo[j].DesiredDeliveryDate = await selectedDateInit()
       orderinfo[j].DesiredDeliveryDateCopy = orderinfo[j].DesiredDeliveryDate
       let item = orderinfo[j];
       let SalesGroupInfo = item.SalesGroupInfo ? item.SalesGroupInfo : ""
       let v1 = item.SelectContractor ? item.SelectContractor : ""
       let v2 = item.ItemGroup ? item.ItemGroup : ""
       let infoArr = await getListArSalesRepresentatives(SalesGroupInfo, v1, v2)
       let Salesperson = infoArr.length > 0 ? infoArr[0].Salesperson : ""
       let SalespersonName = infoArr.length > 0 ? infoArr[0].SalespersonName : ""
       let StgLocationInit = await API.graphql(graphqlOperation(queryArItemMastersBySAPCODEIndex, { SAPCODE: item.Product_Code,filter:{ARBSuppAgencyCode:{eq: useGroupClassification},DelFlag: {ne: "1"}} }));
       if (StgLocationInit.data.queryArItemMastersBySAPCODEIndex !== null && StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"].length > 0) {
        //DT在庫区分の取得
         orderinfo[j].DTStockTier = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].InventoryTier
         //DT通常LTの取得
         orderinfo[j].DTNormalLT = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT
         //直送納期
         if (item.Sales_Document_Type === "ZT11") {
           if (!isNaN(parseInt(StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT))) {
             let DropDeliveryTime = await addDays((moment(new Date()).utcOffset(9).format('YYYY/MM/DD')), parseInt(StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT))
             orderinfo[j].DropDeliveryTime = moment(DropDeliveryTime).utcOffset(9).format('YYYY/MM/DD')
           } else {
            orderinfo[j].DropDeliveryTime = StgLocationInit.data.queryArItemMastersBySAPCODEIndex["items"][0].LatestLT
           }
           orderinfo[j].ShortestRefTime = ""
         }
       }
       //最短参考納期
        if (item.Sales_Document_Type === "ZT31" || item.Sales_Document_Type === "ZS01") {
       if(item.ShippingDestination){
          let date = new Date()
          if (date.getHours() >= 0 && date.getHours() < 12) {
            let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (2 + parseInt(item.FukuiSeinoLT ? item.FukuiSeinoLT : 0)))
            orderinfo[j].ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
          } else if (date.getHours() >= 12 && date.getHours() < 24) {
            let ShortestRefTime = await addDays((moment(new Date).utcOffset(9).format('YYYY/MM/DD')), (3 + parseInt(item.FukuiSeinoLT ? item.FukuiSeinoLT : 0)))
            orderinfo[j].ShortestRefTime = moment(ShortestRefTime).utcOffset(9).format('YYYY/MM/DD')
          }
        }
        orderinfo[j].DropDeliveryTime = ""
        }
        //規定数量の表示、小分け
        let RegQtyAndBulkSales = await getRegQtyAndBulkSales(item.Product_id)
        orderinfo[j].RegQty = RegQtyAndBulkSales.RegQty
        orderinfo[j].BulkSales = RegQtyAndBulkSales.BulkSales
        item.RegQty = orderinfo[j].RegQty
        item.BulkSales = orderinfo[j].BulkSales
        //金額合計欄を表示
        let arr = []
        arr = await SalesUnitPriceScaleFun(item)
        let SalesUnitPriceObj = await SalespriceInfo(item, arr,j)
        orderinfo[j].BulkShippingFee = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.BulkShippingFee
        orderinfo[j].CoolDeliveryCharge = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.CoolDeliveryCharge
        orderinfo[j].OrdQty = Object.keys(SalesUnitPriceObj).length > 0 && SalesUnitPriceObj.SalesUnitPriceObj.OrdQty
        orderinfo[j].SalesUnitPriceScale = SalesUnitPriceObj.price
        orderinfo[j].PurchUnitPriceScale = await PurchpriceInfo(item, arr,j)
        item = orderinfo[j]
        hopeDateVal = orderinfo[0].DesiredDeliveryDate
       PriceCalculation(orderinfo)
         const result = await API.graphql(
           graphqlOperation(createArAgencyOrderInfo, {
             input: {
               id: item.id,
               Agency_id: agencyId,
               AgencyEmail: agencyEmail,
               AgencyName: Agency_Name,
               GroupName: Company_Name,
               Salesperson: Salesperson,
               SalespersonName:SalespersonName,
               QuoteNumber: RepurchaseQuoteNumber,
               comments: item.OrderShippingNotes,
               DesiredDeliveryDate: item.DesiredDeliveryDate,
               EndUserId: item.EndUserId,
               EndUserNm: item.EndUserNm,
               OrderAddress: item.OrderAddress,
               OrderChargeName: OrderChargeNameText,
               OrderNumber: item.OrderNumber /* 発注番号 */,
               OrderPostCode: item.OrderPostCode,
               OrderQuantity: parseFloat(item.OrderQuantity),
               OrderRemarks: item.OrderRemarks,
               OrderShipContactNm: item.OrderShipContactNm, /*出荷先担当者名 */
               OrderShippingNotes: item.OrderShippingNotes, /*出荷備考 */
               OrderStatus: "1" /*登録完了 一時保存 見積受付*/,
               OrderTelNo: item.OrderTelNo,
               Price: item.SalesUnitPriceScale,
               Sales_Document_Type: item.Sales_Document_Type,
               ShippingDestination: item.ShippingDestination,
               ShippingDestinationNm: item.ShippingDestinationNm,
               ShippingOrderNumber: item.ShippingOrderNumber, /*出荷先注文番号 */
               Wholes_Price: item.UnitPriceAftDis ? item.UnitPriceAftDis : item.SalesUnitPriceScale,
               Segment: item.Segment,
               SelectContractor: item.SelectContractor,
               SelectContractorNm: item.SelectContractorNm,
               invoiceSubtotal: item.invoiceSubtotal,
               invoiceTotal: item.invoiceTotal,
               invoiceFax: item.invoiceFax,
               invoicePayTotal: item.invoicePayTotal,
               unitPrice: item.unitPrice,
               InHouseRe: item.InHouseRe,
               FukuiWarehouseCSVRe: item.FukuiWarehouseCSVRe,
               PurchOrderRe: item.PurchOrderRe,
               TorayEDIRe: item.TorayEDIRe,
               Sales_confirm_flag: item.Sales_confirm_flag,
               Quote_reg_flag: item.Quote_reg_flag,
               CompanMemo: item.CompanMemo,
               SalesType: item.SalesType,
               Storage_Location_U: item.Storage_Location_U,
               SalesUnitPriceScale: item.SalesUnitPriceScale,
               UnitPriceAftDis: item.UnitPriceAftDis,
               Product_Code: item.Product_Code,
               Product_Name_j: item.Product_Name_j,
               Product_id: item.Product_id,
               DetailNo: item.DetailNo,
               RegistrationDate: new Date(),
               OrderDetailsNumber: item.OrderDetailsNumber,
               Contractor: item.SelectContractor,
               ContractorNm: item.SelectContractorNm,
               PurchUnitPriceScale: item.PurchUnitPriceScale,
               RegQty: item.RegQty ? item.RegQty : "",
               BulkSales: item.BulkSales ? item.BulkSales : "",
               Capacity: item.Capacity ? item.Capacity : "",
               SuppName: item.SuppName ? item.SuppName : "",
               BulkShippingFee: item.BulkShippingFee ? item.BulkShippingFee : "",
               CoolDeliveryCharge: item.CoolDeliveryCharge ? item.CoolDeliveryCharge : "",
               CapacityUnit: item.CapacityUnit ? item.CapacityUnit : "",
               PriceUnit: item.PriceUnit ? item.PriceUnit : "",
               UserAuth: "2",
               SubId:subId,
               TorayShipCode : item.TorayShipCode,
               Plant : item.Plant,
               Supplier:item.Supplier,
               purchasing_Group:item.SalesGroupInfo,
               SalesGroup:item.SalesGroupInfo,
               Tax_Code:"V4",
               Basic_Quantity_Unit:item.BaseNumUnit,
               EndUserIdDirectDelivery:item.EndUserIdDirectDelivery ? item.EndUserIdDirectDelivery : "",
               ShortestRefTime:item.ShortestRefTime,
               DropDeliveryTime:item.DropDeliveryTime,
               DTStockTier:item.DTStockTier,
               DTNormalLT:item.DTNormalLT,
               User_Name :User_Name,
               AssigneeCode:AssigneeCode,
               Usage_System:usageSystem
             },
           })
         );
       } catch (error) {
         if (error.message === 'No current user') {
           // ユーザープールに障害が発生した場合
           console.log('User pool is invalid.Please reauthenticate.');
           // ログイン画面に戻る
           signOut();
         } else {
           // 他のエラーを処理する
           console.log('An error occurred:', error);
         }
       }
     }
     orderReadonly = false
     setQuoteNumber(RepurchaseQuoteNumber)
     setSearchProgress(false)
   }
   return (
     <Container
       style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh" }}
     >
       <Row
         style={{
           borderLeft: "4px solid #804F29",
           marginTop: "1rem",
           paddingLeft: "4px",
           marginBottom: "1rem",
         }}
       >
         <h4>
           注文登録
         </h4>
       </Row>
       <Grid style={{ backgroundColor: "white", padding: "1em", border: '3px solid #804F29' }}>
         <Row>
           <Col>
             <InputGroup className="mb-3">
               <InputGroup.Text className={classes.textlabel} id="basic-name">
                 登録番号
               </InputGroup.Text>
               <FormControl
                 autocomplete="off"
                 placeholder="納品請求書の宛名にご指定がある場合ご入力下さい"
                 aria-label=""
                 aria-describedby="basic-name"
                 id="QuoteNumber"
                 className={classes.textField}
                 value={QuoteNumber}
                 readOnly={true}
                 maxLength="18"
               />
             </InputGroup>
           </Col>
         </Row>
         <Row>
           <Col>
             <div style={{ display: "flex", alignItems: "left" }}>
               <InputGroup.Text className={classes.textlabel} id="basic-name">
                 受注先名
               </InputGroup.Text>
               <FormControlsk style={{ width: "360px" }}>
                 <InputLabel id="demo-simple-select-label"></InputLabel>
                 <Select
                   labelId="demo-simple-select-label"
                   id="selectContractorOptions"
                   value={selectContractorOptions ? selectContractorOptions.split(":")[1] : ""}
                   onChange={handleContractorChange}
                   MenuProps={MenuProps}
                   readOnly={true}
                   autoWidth
                   input={<BootstrapInput />}
                 >
                   <MenuItem
                     key={selectContractorOptions.split(":")[1]}
                     value={selectContractorOptions.split(":")[1]}
                   >
                     {selectContractorOptions.split(":")[1]}
                   </MenuItem>
                 </Select>
               </FormControlsk>
             </div>
           </Col>
           <Col>
           <div style={{ display: "flex", alignItems: "left" }}>
             <InputGroup.Text className={classes.textlabel} id="basic-name">
                 担当者名
             </InputGroup.Text>
             <FormControl
                 autocomplete="on"
                 style={{ width: "100%" }}
                 type="text"
                 placeholder="納品請求書の宛名にご指定がある場合ご入力下さい"
                 name="OrderChargeNameText"
                 id="OrderChargeNameText"
                 className={classes.textField}
                 value={OrderChargeNameText ? OrderChargeNameText : ""}
                 onChange={(e) => {
                   txtChangedOrderChargeNameText(e);
                 }}
                 disabled={orderReadonly}
                 maxLength="20"
               />
              </div>
           </Col>
         </Row>
       </Grid>
       <Row
         style={{
           marginTop: "1rem",
           paddingLeft: "4px",
         }}
       >
         <h4>
         </h4>
       </Row>
       <Grid
         style={{
           backgroundColor: "white",
           padding: "1em",
         }}
       >
         <Row>
           {
             orderinfo.map((item, index) => {
               return (
                 <div key={item.id} style={{ marginTop: 10, width: "100%" }}>
                   <Dialog
                     open={open3}
                     onClose={handleCloseNG3}
                     aria-labelledby="alert-dialog-title"
                     aria-describedby="alert-dialog-description"
                   >
                     <DialogTitle id="alert-dialog-title"></DialogTitle>
                     <DialogContent>
                       <DialogContentText id="alert-dialog-description">
                         {"サンプルを選択します"}
                       </DialogContentText>
                     </DialogContent>
                     <DialogActions style={{ width: "85%" }}>
                       <Button onClick={() => handleCloseOK3(item, orderinfo)} color="secondary">
                         {"YES"}
                       </Button>
                       <Button onClick={() => handleCloseNG3()} color="primary">
                         {"NO"}
                       </Button>
                     </DialogActions>
                   </Dialog>
                   <TableContainer>
                     <Table style={{ border: '3px solid #804F29', }}>
                       <TableHead>
                         <TableRow style={{ border: '1px dashed #804F29'}}>
                           <TableCell style={{ border: '1px dashed #804F29',width:"10%"}} align="center">品目</TableCell>
                           <TableCell style={{ border: '1px dashed #804F29' }}>{item.Product_Code}</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' ,width:"8%"}} >
                             販売種別
                           </TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: "right",width:"7%" }}>
                             <FormControlsk fullWidth>
                               <Select
                                 className="MuiSelect-filled.MuiSelect-filled"
                                 variant="filled"
                                 labelId="demo-simple-select-label"
                                 id="SelectContractor"
                                 value={(item.SelectContractor && (item.SelectContractor === "AA89104100" || item.SelectContractor === "AA89108100" || item.SelectContractor === "AA89106200")) ? "サンプル" : "通常購入"}
                                 input={<BootstrapInput />}
                                 onChange={(e) => handleChangeSalesType(e, item, orderinfo)}
                                 disabled={orderReadonly}
                               >
                                 <MenuItem value="" key=""></MenuItem>
                                 <MenuItem
                                   key={"通常購入"}
                                   value={"通常購入"}
                                 >
                                   {"通常購入"}
                                 </MenuItem>
                                 <MenuItem
                                   key={"サンプル"}
                                   value={"サンプル"}
                                 >
                                   {"サンプル"}
                                 </MenuItem>
                               </Select>
                             </FormControlsk>
                           </TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29", background: "white", textAlign: 'center',width:"7%", }}>保管場所</TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29", background: "white",width:"7%" }}>
                             <FormControlsk fullWidth>
                               <Select
                                 className="MuiSelect-filled.MuiSelect-filled"
                                 variant="filled"
                                 labelId="demo-simple-select-label"
                                 id="Storage_Location_U"
                                 input={<BootstrapInput />}
                                 value={item.Storage_Location_U ? item.Storage_Location_U : ""}
                                 disabled={orderReadonly}
                                 onChange={(e) => handStorageLoc(e, item, orderinfo,index)}
                               >
                                 <MenuItem value="" key=""></MenuItem>
                                 {item.Storage_Location_U && item["StgLocationlist"].length === 0 ? <MenuItem
                                   key={item.Storage_Location_U}
                                   value={item.Storage_Location_U}
                                 >
                                   {item.Storage_Location_U}
                                 </MenuItem> : item["StgLocationlist"] && item["StgLocationlist"].map((name) => (
                                   <MenuItem
                                     key={name}
                                     value={name}
                                   >
                                     {name}
                                   </MenuItem>
                                 ))}
                               </Select>
                             </FormControlsk></TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29", background: "white",width:"7%" }}></TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29", background: "white",width:"7%" }}></TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                         <TableRow style={{ borderBottom: '3px solid #804F29'}}>
                           <TableCell style={{ border: '1px dashed #804F29' }} align="center" >品名</TableCell>
                           <TableCell style={{ border: '1px dashed #804F29' }}>{item.Product_Name_j}</TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' }}><span style={{color:"red",marginRight: "5px",fontSize: "14px",fontweight: "bold"}}>*</span>希望納期</TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29", background: "white" }}>
                             <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                               <DatePicker
                                 disableToolbar
                                 disablePast
                                 margin="normal"
                                 cancelLabel=""
                                 format="yyyy/MM/dd"
                                 id="hopedate"
                                 label=""
                                 helperText=""
                                 error={false}
                                 value={item.DesiredDeliveryDate ? item.DesiredDeliveryDate : selectedDate}
                                 onChange={(e) => handleSelectedDate(e, item, orderinfo,index)}
                                 disabled={orderReadonly}
                                 shouldDisableDate={disableWeekends}
                                 InputProps={{
                                   disableUnderline: true, style: { fontSize: "14px", marginLeft: "19%", marginTop: "2px", }
                                 }}
                               />
                             </MuiPickersUtilsProvider>
                           </TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>最短参考納期</TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: 'white', textAlign: 'center' }}>{item.ShortestRefTime}</TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>直送納期</TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: 'white', textAlign: 'center' }}>{item.DropDeliveryTime}</TableCell>
                         </TableRow>
                         <TableRow style={{ border: '1px dashed #804F29' }}>
                           <TableCell style={{ border: '1px dashed #804F29' }}>受注番号</TableCell>
                           <TableCell style={{ border: '1px dashed #804F29' }}>{item.OrderDetailsNumber}</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' }}><span style={{color:"red",marginRight: "5px",fontSize: "14px",fontweight: "bold"}}>*</span>数量</TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>
                            <FormControl
                             autocomplete="off"
                             placeholder=""
                             aria-label=""
                             aria-describedby="basic-name"
                             id="OrderQuantity"
                             className={classes.textField}
                             style={{ width: "90%", border: "none", fontSize: "14px", textAlign: 'center', }}
                             value={item.OrderQuantity ? item.OrderQuantity : ""}
                             maxLength="11"
                             onBlur={(e) => handleBlurOrderQuantity(e, item, index)}
                             onChange={(e) => handleOrderQuantity(e, item, orderinfo)}
                             disabled={orderReadonly}
                           /></TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>福井西濃LT</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: 'white', textAlign: 'center' }}>{item.FukuiSeinoLT ? item.FukuiSeinoLT : ""}</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>DT通常LT</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: 'white', textAlign: 'center' }}>{item.DTNormalLT ? item.DTNormalLT : ""}</TableCell>
                         </TableRow>
                         <TableRow>
                           <TableCell style={{ border: '1px dashed #804F29' }}> 発注先注文番号</TableCell>
                           <TableCell style={{ border: '1px dashed #804F29' }}>
                           <FormControl
                               name="OrderNumber"
                               type="text"
                               className={classes.textField}
                               value={item.OrderNumber ? item.OrderNumber : ""}
                               style={{ width: '60vh' }}
                               onChange={(e) => {
                                 txtChangedOrderNo(e, item)
                               }}
                               maxLength="35"
                               disabled={orderReadonly}
                             />
                           </TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' }}>規定数量</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: 'white', textAlign: 'center' }}>{item.RegQty ? item.RegQty : ""}</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>小分け</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: 'white', textAlign: 'center' }}>{item.BulkSales === "0" ? "不可" : (item.BulkSales === "1" ? "可" : "")}</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>DT在庫区分</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: 'white', textAlign: 'center' }} >{item.DTStockTier ? item.DTStockTier : ""}</TableCell>
                         </TableRow>
                         <TableRow style={{ border: '1px dashed #804F29' }}>
                           <TableCell style={{ border: '1px dashed #804F29' }}>発注先備考</TableCell>
                           <TableCell><FormControl
                             autocomplete="off"
                             placeholder="弊社へのご連絡事項がございます場合ご入力ください。　例)同一ロットでお願いします"
                             aria-label=""
                             aria-describedby="basic-name"
                             id="OrderRemarks"
                             className={classes.textField}
                             style={{ width: '60vh' }}
                             defaultValue={item.OrderRemarks ? item.OrderRemarks : ""}
                             onChange={(e) => {
                               txtChangedOrderRemarksText(e, item);
                             }}
                             maxLength="40"
                             disabled={orderReadonly}
                           /></TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' }}>販売単価</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", }}><FormControl
                             autocomplete="off"
                             placeholder=""
                             aria-label=""
                             aria-describedby="basic-name"
                             id="SalesUnitPriceScale"
                             className={classes.textField}
                             style={{ width: "90%", border: "none", fontSize: "14px", textAlign: 'center' }}
                             value={item.SalesUnitPriceScale ? numtomoney(moneytonum(item.SalesUnitPriceScale)) : ""}
                             maxLength="11"
                             disabled={orderReadonly === true ? orderReadonly : ((item.SelectContractor && (item.SelectContractor === "AA89104100" || item.SelectContractor === "AA89108100" || item.SelectContractor === "AA89106200")) ? true : (SalesUnitPriceScaleDisable[index].Disable === true ? true : false))}
                             onChange={(e) => handleSalesUnitPriceScaleChange(e, item)}
                           /></TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>値引き後単価</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: 'center' }}><FormControl
                             autocomplete="off"
                             placeholder=""
                             aria-label=""
                             aria-describedby="basic-name"
                             id="UnitPriceAftDis"
                             className={classes.textField}
                             style={{ width: "90%", border: "none", fontSize: "14px", textAlign: 'center' }}
                             value={item.UnitPriceAftDis ? numtomoney(moneytonum(item.UnitPriceAftDis)) : ""}
                             maxLength="11"
                             onChange={(e) => { handleUnitPriceAftDisChange(e, item) }}
                             disabled={orderReadonly === true ? orderReadonly : ((item.SelectContractor && (item.SelectContractor === "AA89104100" || item.SelectContractor === "AA89108100" || item.SelectContractor === "AA89106200")) ? true : false)}
                           /></TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", textAlign: 'center' }}>仕入単価</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: 'white', textAlign: 'center' }}>{item.PurchUnitPriceScale ? numtomoney(moneytonum(item.PurchUnitPriceScale)) : ""}</TableCell>
                         </TableRow>
                         <TableRow>
                           <TableCell rowspan={5} style={{ border: '1px dashed #804F29' }}><p style={{ width: "60px", height: "30px", textAlign: "center", lineHeight: "30px", background: "rgb(236, 180, 69)", color: "white" }}>必須</p>出荷先</TableCell>
                           <TableCell rowspan={5} style={{ border: '1px dashed #804F29' }}>
                           <div>
                                <Box sx={{ width: '500px' }}>
                                  <FormHelperText>登録が無い場合は新規申請して下さい</FormHelperText>
                                  <Autocomplete
                                    className={classes.autocomplete}
                                    id="ShippingData"
                                    options={ShipingOptions.Invs}
                                    disableClearable
                                    value={item.ShippingData ? item.ShippingData.split(":")[1] : null}
                                    disabled={orderReadonly}
                                    onChange={(e, value) => {
                                      handleChange1(value, item, addressList, orderinfo,index);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                  />
                                </Box>
                                <div style={{
                                  width: '60vh',
                                  marginTop: 10,
                                  backgroundColor: "white",
                                  // paddingLeft: "2rem",
                                  borderTop: "4px solid #ECB445",
                                  borderBottom: "4px solid #ECB445",
                                  borderRight: "4px solid #ECB445",
                                  borderLeft: "40px solid #ECB445",
                                }}>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text
                                      className={classes.textlabel}
                                      id="basic-name"
                                    >
                                      出荷先名
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby="basic-name"
                                      id="ShippingDestinationNm"
                                      className={classes.textField}
                                      value={item.ShippingDestinationNm ? item.ShippingDestinationNm : ""}
                                      readOnly={true}
                                    />
                                  </InputGroup>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text
                                      className={classes.textlabel}
                                      id="basic-name"
                                    >
                                      住　　所
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby="basic-name"
                                      id="OrderAddress"
                                      className={classes.textField}
                                      value={item.OrderAddress ? item.OrderAddress : ""}
                                      readOnly={true}
                                    />
                                  </InputGroup>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text
                                      className={classes.textlabel}
                                      id="basic-name"
                                    >
                                      電話番号
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby="basic-name"
                                      id="OrderTelNo"
                                      className={classes.textField}
                                      value={item.OrderTelNo ? item.OrderTelNo : ""}
                                      readOnly={true}
                                    />
                                  </InputGroup>
                                </div>
                              </div>
                           </TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' }}>社内備考（請書）</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white" }} colSpan={5}>
                             <FormControl
                               autocomplete="off"
                               placeholder=""
                               aria-label=""
                               aria-describedby="basic-name"
                               id="InHouseRe"
                               className={classes.textField}
                               style={{ width: "90%", border: "none", fontSize: "14px", textAlign: 'left' }}
                               value={item.InHouseRe ? item.InHouseRe : ""}
                               maxLength="132"
                               disabled={orderReadonly === true ? orderReadonly : ((item.SelectContractor && (item.SelectContractor === "AA89104100" || item.SelectContractor === "AA89108100" || item.SelectContractor === "AA89106200")) ? true : false)}
                               onChange={(e) => { handleInHouseReChange(e, item) }}
                             />
                           </TableCell>
                         </TableRow>
                         <TableRow>
                         <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' }}>購買発注備考</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white" }} colSpan={5}>
                             <FormControl
                               autocomplete="off"
                               placeholder=""
                               aria-label=""
                               aria-describedby="basic-name"
                               id="PurchOrderRe"
                               className={classes.textField}
                               style={{ width: "90%", border: "none", fontSize: "14px", textAlign: 'left' }}
                               value={item.PurchOrderRe ? item.PurchOrderRe : ""}
                               maxLength="132"
                               onChange={(e) => { handlePurchOrderReChange(e, item) }}
                               disabled={orderReadonly === true ? orderReadonly : ((item.Sales_Document_Type && item.Sales_Document_Type === "ZT11") ? false : true)}
                             />
                           </TableCell>
                         </TableRow>
                         <TableRow>
                         <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' }}>東レEDI備考</TableCell>
                           <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white", }} colSpan={5}>
                             <FormControl
                               autocomplete="off"
                               placeholder=""
                               aria-label=""
                               aria-describedby="basic-name"
                               id="TorayEDIRe"
                               maxLength="40"
                               className={classes.textField}
                               style={{ width: "90%", border: "none", fontSize: "14px", textAlign: 'left' }}
                               value={item.TorayEDIRe ? item.TorayEDIRe : ""}
                               onChange={(e) => { handleTorayEDIReChange(e, item) }}
                               disabled={orderReadonly === true ? orderReadonly : ((item.Sales_Document_Type && item.Sales_Document_Type === "ZT11" && (item.SuppName === "ダウ・東レ_特価" || item.SuppName === "ダウ・東レ_BOOK")) ? false : true)}
                             />
                           </TableCell>
                         </TableRow>
                         <TableRow>
                         <TableCell style={{padding: 0,margin: 0,whiteSpace: "nowrap", border: "1px dashed #804F29",borderLeft:'3px solid #804F29', background: "white", textAlign: 'center' }}>福井倉庫CSV備考</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,whiteSpace: "nowrap",border: "1px dashed #804F29", background: "white" }} colSpan={5}>
                             <FormControl
                               autocomplete="off"
                               placeholder=""
                               aria-label=""
                               aria-describedby="basic-name"
                               id="FukuiWarehouseCSVRe"
                               className={classes.textField}
                               style={{ width: "90%", border: "none", fontSize: "14px", textAlign: 'left' }}
                               value={item.FukuiWarehouseCSVRe ? item.FukuiWarehouseCSVRe : ""}
                               maxLength="30"
                               onChange={(e) => { handleFukuiWarehouseCSVReChange(e, item) }}
                               disabled={orderReadonly === true ? orderReadonly : ((item.Sales_Document_Type && item.Sales_Document_Type === "ZT11") ? true : false)}
                             />
                           </TableCell>
                         </TableRow>
                         <TableRow>
                         <TableCell style={{padding: 0,margin: 0,border: "1px dashed #804F29",borderLeft:'3px solid #804F29',borderBottom:'3px solid #804F29', background: "white", textAlign: 'center' }}>社内メモ</TableCell>
                           <TableCell style={{ padding: 0,margin: 0,border: "1px dashed #804F29",borderBottom:'3px solid #804F29', background: "white" }} colSpan={5}>
                             <FormControl
                               autocomplete="off"
                               placeholder=""
                               aria-label=""
                               aria-describedby="basic-name"
                               id="CompanMemo"
                               className={classes.textField}
                               style={{ width: "90%", border: "none", fontSize: "14px", textAlign: 'left' }}
                               value={item.CompanMemo ? item.CompanMemo : ""}
                               maxLength="30"
                               onChange={(e) => { handleCompanMemoChange(e, item) }}
                               disabled={orderReadonly}
                             />
                           </TableCell>
                         </TableRow>
                         <TableRow>
                           <TableCell style={{ border: '1px dashed #804F29' }}>
                             出荷先注文番号
                           </TableCell>
                           <TableCell colSpan={7} style={{ border: '1px dashed #804F29' }}>
                             <div style={{ display: 'flex' }}>
                               <FormControl
                                 autocomplete="off"
                                 placeholder="発注先注文番号と異なる場合はご入力下さい"
                                 aria-label=""
                                 aria-describedby="ShippingOrderNumber"
                                 id="ShippingOrderNumber"
                                 className={classes.textKField}
                                 defaultValue={item.ShippingOrderNumber ? item.ShippingOrderNumber : ""}
                                 onChange={(e) => {
                                   txtChangedShippingOrderNumberText(e, item);
                                 }}
                                 style={{ minWidth: '30%' }}
                                 disabled={orderReadonly}
                                 maxLength="20"
                               />
                               <InputGroup className="mb-3">
                                 <InputGroup.Text className={classes.textKField} id="basic-name">
                                   出荷先担当者名
                                 </InputGroup.Text>
                                 <FormControl
                                   autocomplete="off"
                                   placeholder="納品ご指定がある場合ご入力下さい"
                                   aria-label=""
                                   aria-describedby="basic-name"
                                   id="OrderShipContactNm"
                                   className={classes.textKField}
                                   defaultValue={item.OrderShipContactNm ? item.OrderShipContactNm : ""}
                                   onChange={(e) => {
                                     txtChangedOrderShipContactNmText(e, item);
                                   }}
                                   maxLength="20"
                                   disabled={orderReadonly}
                                 />
                               </InputGroup>
                             </div>
                           </TableCell>
                         </TableRow>
                         <TableRow style={{ border: '1px dashed #804F29' }}>
                           <TableCell style={{ border: '1px dashed #804F29' }}>出荷備考</TableCell>
                           <TableCell colSpan={7}><FormControl
                             autocomplete="off"
                             placeholder="送り状備考に記載希望内容　　例)午前着にして下さい。"
                             aria-label=""
                             aria-describedby="basic-name"
                             id="OrderShippingNotes"
                             className={classes.textField}
                             style={{ width: '70vh' }}
                             defaultValue={item.OrderShippingNotes ? item.OrderShippingNotes : ""}
                             onBlur={(e) => {
                               changeOinput(e, item);
                             }}
                             maxLength="40"
                             disabled={orderReadonly}
                           /></TableCell>
                         </TableRow>
                         <TableRow style={{ border: '1px dashed #804F29' }}>
                           <TableCell style={{ border: '1px dashed #804F29' }}>
                             <p style={{ width: "60px", height: "30px", textAlign: "center", lineHeight: "30px", background: "rgb(236, 180, 69)", color: "white" }}>必須</p>エンドユーザー
                           </TableCell>
                           <TableCell style={{ border: '1px dashed #804F29' }} colSpan={7}>
                             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                               <Box sx={{ width: 500 }}>
                                 <FormHelperText>登録が無い場合は新規申請して下さい</FormHelperText>
                                 <Autocomplete
                                   className={classes.autocomplete}
                                   id="endUserGroup"
                                   options={endUserOptions.Invs}
                                   disabled={orderReadonly === true ? orderReadonly : (item.SalesType === "サンプル" ? true : false)}
                                   value={item.endUserGroup ? item.endUserGroup.split(":")[1] : ""}
                                   onChange={(e, value) => {
                                     handleEndUserChange(value, item,index)
                                   }}
                                   renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                 />
                               </Box>
                               <div style={{ display: "flex", alignItems: "center" }}>
                                 <FormControl
                                   placeholder="販売単価について営業確認して下さい"
                                   aria-label=""
                                   aria-describedby="basic-name"
                                   id="SalesConfirmClick"
                                   readOnly={true}
                                   style={{ display: item.SalesUnitPriceScale ? "none" : "block", width: "300px", border: "1px dashed", height: "50px" }}
                                 />
                                 <Button
                                   className="btn btn-dark "
                                   size="large"
                                   disabled={orderReadonly}
                                   style={{
                                     marginLeft: "10px",
                                   }}
                                   onClick={() => {
                                     SalesConfirmClick(item);
                                   }}
                                 >
                                   営業確認
                                 </Button>
                               </div>
                             </div>
                           </TableCell>
                         </TableRow>
                       </TableBody>
                     </Table>
                   </TableContainer>
                 </div>
               )
             })
           }
         </Row>
       </Grid>
       <Row>
         <Col>
           <Row>
             <h4
               style={{
                 paddingLeft: "4px",
                 color: "black",
               }}
             >
             </h4>
           </Row>
           <Row style={{ padding: "1rem" }}>
             <Col style={{ backgroundColor: "white" }}>
               <TableContainer>
                 <Table
                   className={classes ? classes.table : undefined}
                   aria-label="spanning table"
                   style={{ border: "none",visibility: orderinfo.length > 0 ? "visible" : "hidden"}}
                 >
                   <TableBody>
                     <TableRow>
                       <TableCell style={{
                         borderBottom: "none",
                         fontWeight: "bold",
                       }}
                         colSpan={2}></TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                           fontWeight: "bold",
                         }}
                         colSpan={2}
                       >
                         製品合計（税抜）
                       </TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                         }}
                         colSpan={2}
                         align="right"
                       >
                         {invoiceSubtotal}円
                       </TableCell>
                     </TableRow>
                     <TableRow style={{display:"none"}}>
                       <TableCell style={{
                         borderBottom: "none",
                         fontWeight: "bold",
                       }}
                         colSpan={2}></TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                           fontWeight: "bold",
                         }}
                         colSpan={2}
                       >
                         キャンペーン特価
                       </TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                           color: "red"
                         }}
                         colSpan={2}
                         align="right"
                       >
                         {0}円
                       </TableCell>
                     </TableRow>
                     <TableRow style={{ display: PriceRateTotal.quantityPrice !== 0 ? "table-row" : "none" }}>
                       <TableCell style={{
                         borderBottom: "none",
                         fontWeight: "bold",
                       }}
                         colSpan={2}></TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                           fontWeight: "bold",
                         }}
                         colSpan={2}
                       >
                         数量特価
                       </TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                           color: "red"
                         }}
                         colSpan={2}
                         align="right"
                       >
                         {PriceRateTotal.quantityPrice !== 0 ? "-" + PriceRateTotal.quantityPrice : PriceRateTotal.quantityPrice}円
                       </TableCell>
                     </TableRow>
                     <TableRow>
                       <TableCell style={{
                         borderBottom: "none",
                         fontWeight: "bold",
                       }}
                         colSpan={2}></TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                           fontWeight: "bold",
                         }}
                         colSpan={2}
                       >
                         送料／手数料（税抜）
                       </TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                         }}
                         colSpan={2}
                         align="right"
                       >
                         {invoiceTotal}円
                       </TableCell>
                     </TableRow>
                     {/* <TableRow>
                       <div>
                         <span style={{ color: "red", 'fontSize': '12px' }}>※単価が「ー」となっている品目は御見積商品となり、<br></br>
                           該当商品が含まれている場合は、合計金額が表示されません。</span>
                       </div>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                           fontWeight: "bold",
                         }}
                         colSpan={3}
                       >
                         消費税（10%）
                       </TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px dashed gray",
                         }}
                         colSpan={2}
                         align="right"
                       >
                         {invoiceFax}円
                       </TableCell>
                     </TableRow> */}
                     <TableRow>
                     <div>
                         <span style={{ color: "red", 'fontSize': '12px' }}>※単価が「ー」となっている品目は御見積商品となり、<br></br>
                           該当商品が含まれている場合は、合計金額が表示されません。</span>
                       </div>
                       <TableCell
                         style={{
                           borderBottom: "1px solid gray",
                           fontWeight: "bold",
                         }}
                         colSpan={3}
                       >
                         注文合計（税抜）
                       </TableCell>
                       <TableCell
                         style={{
                           borderBottom: "1px solid gray",
                           fontWeight: "bold",
                         }}
                         colSpan={2}
                         align="right"
                       >
                         {invoicePayTotal}円
                       </TableCell>
                     </TableRow>
                     <TableRow>
                       <div>
                       </div>
                       <TableCell
                         style={{
                           borderBottom: "none",
                         }}
                         colSpan={3}
                       >
                         消費税については請求書にてご確認ください。
                       </TableCell>
                     </TableRow>
                   </TableBody>
                 </Table>
               </TableContainer>
               <Grid>
                 <Row style={{ marginBottom: "1em" }}>
                   <Col xs={12} md={8} style={{ display: "none" }}>
                     <FormControlLabel
                       control={
                         <Checkbox
                           checked={state.checkedB}
                           onChange={handleChange}
                           name="checkedB"
                           color="secondary"
                         />
                       }
                       label="Admin専用ボックス与信確認しないチェックボックス"
                     />
                   </Col>
                 </Row>
                 <div hidden={flg}></div>
               </Grid>
             </Col>
           </Row>
         </Col>
         <Col
           style={{
             display: orderinfo.length > 0 ? "flex" : "none",
             textAlign: "center",
             justifyContent: "end",
             marginBottom: "1rem",
             marginTop: "1rem",
           }}
           xs={12}
           align="right"
         >
           <Button
             className="btn btn-danger "
             size="large"
             disabled={orderReadonly}
             style={{
               marginLeft: "10px",
             }}
             onClick={() => {
               const OrderNumber = orderinfo[0].OrderNumber ? orderinfo[0].OrderNumber : "";
               const ProductCode = orderinfo[0].Product_Code;
               const OrderQuantity = orderinfo[0].OrderQuantity + "/" + orderinfo[0].CapacityUnit;
               const RequiredQuantity = orderinfo[0].RegQty + "/" + orderinfo[0].PriceUnit;
               const Availability = orderinfo[0].BulkSales === "0" ? "不可" : "可";
               const endUser = orderinfo[0].EndUserNm;
               const email = 'recipient@example.com';
               const ccemails = ccEmail(orderinfo[0].SalesGroupInfo)
               const subject = `エア・ブラウンARBOS_見積回答_${ProductCode}_${OrderNumber}`;
               const title = getTitle(useGroupClassification)
               let body = `${orderinfo[0].SelectContractorNm} 御中\n\nいつもお世話になっております。\nエア・ブラウン㈱${title}シリコーン窓口です。\n\nお問い合わせありがとうございます。下記の通り御見積申し上げます。\n\n`;
               orderinfo.map(itemInfo => {
                 let subdivisionfees = itemInfo.BulkShippingFee
                 let unit = BulkShippingFeeUnit(itemInfo)
                 const OrderNumber = itemInfo.OrderDetailsNumber;
                 const ProductCode = itemInfo.Product_Name_j;
                 const OrderQuantity = itemInfo.OrderQuantity + "　" + itemInfo.BaseNumUnit;
                 const RequiredQuantity = numtomoney(itemInfo.SalesUnitPriceScale) + "/" + itemInfo.PriceUnit;
                 const Availability = itemInfo.BulkSales === "0" ? "不可" : "可";
                 const endUser = itemInfo.EndUserNm === undefined ? "" : itemInfo.EndUserNm;
                 const RegQty = itemInfo.RegQty === undefined ? "" : itemInfo.RegQty;
                 const PriceUnit = itemInfo.PriceUnit === undefined ? "" : itemInfo.PriceUnit
                 const QuotationReplyLT = itemInfo.QuotationReplyLT === undefined ? "" : itemInfo.QuotationReplyLT
                 const BaseNumUnit = itemInfo.BaseNumUnit === undefined ? "" : itemInfo.BaseNumUnit
                 body +=
                     `　　　　見積番号：${OrderNumber}\n　　　　品　　名：${ProductCode}\n　　　　数　　量：${OrderQuantity}\n　　　　単　　価：\\${RequiredQuantity}\n`
                   + `   規定数量(MOQ)：${RegQty ? RegQty : ""}　${BaseNumUnit ? BaseNumUnit : ""}\n　　　小分け可否：${Availability}\n`
                   + `　　小分け手数料：\\${Number(moneytonum(subdivisionfees)) !== 0 ? numtomoney(subdivisionfees) + '/' + unit : "0"}\n`
                   + `標準リードタイム：${QuotationReplyLT ? (/^[0-9]*$/.test(QuotationReplyLT) === true ? QuotationReplyLT　+ "日(ダウ東レ営業日ベース)" : QuotationReplyLT) : ""}\n`
                   + "　　見積有効期限：次回改定日まで\n"
                   + "　　　　納入場所：貴社ご指定場所国内(離島を除く)\n"
                   + `　エンドユーザー：${endUser}\n`
                   + "　　　　製造者名：\n"
                   + "　　　　　　備考：\n\n";
               })
               body += "※本見積書には、消費税は含まれておりません。\n※納期をお約束したものではございません。\n※有効期限内でも状況によっては価格変更させていただく場合がございます。 ";
               const encodedSubject = encodeURIComponent(subject);
               const encodedBody = encodeURIComponent(body);
               if(ccemails === ""){
                   setMailtoLink(`mailto:${""}?subject=${encodedSubject}`)
               }else{
                   setMailtoLink(`mailto:${""}?cc=${ccemails}&subject=${encodedSubject}`)
               }
               handleSetMsgmail("メール内容");
               handleSetMsgTextmail(body);
               handleClickOpenmail();
             }}
           >
             見積登録
           </Button>
           <Button
             className="btn btn-dark"
             size="large"
             style={{ marginLeft: "10px", display: RepurchaseDisable === false ? "block" : "none" }}
             onClick={() => Repurchase()}
             disabled={RepurchaseDisableInfo}
           >
             再購入
           </Button>
           <Button
             className="btn btn-dark "
             size="large"
             disabled={orderReadonly}
             style={{
               marginLeft: "10px",
             }}
             onClick={() => { goback(); }}
           >
             {" "}
             商品選択へ戻る
           </Button>
           <Button
             className="btn btn-dark"
             size="large"
             disabled={orderReadonly}
             style={{
               marginLeft: "10px",
             }}
             onClick={() => {
               orderClick(true);
             }}
           >
             一時保存
           </Button>
           <Button
             className="btn btn-danger "
             size="large"
             disabled={orderReadonly}
             style={{
               marginLeft: "10px",
             }}
             onClick={() => {
               orderClick(false);
             }}
           >
             登録依頼
           </Button>
         </Col>
       </Row>
       <Dialog
         open={open2}
         onClose={handleCloseNG2}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
       >
         <DialogTitle id="alert-dialog-title">{msg1}</DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-description">
             {msgText}
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleCloseOK2} color="primary" autoFocus>
             {msgbtnOK}
           </Button>
         </DialogActions>
       </Dialog>
       <Dialog style={{ zIndex: "10000" }}
         open={openmail}
         onClose={handleCloseNGmail}
         //  aria-labelledby="alert-dialog-title"
         //  aria-describedby="alert-dialog-description"
         fullWidth maxWidth="md"
       >
         <DialogTitle id="alert-dialog-title">{msgmail}</DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-description">
             {msgTextmail}
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button autoFocus onClick={() => handleCopyClick()} color="primary" >
             クリップボードにコピー
           </Button>
         </DialogActions>
       </Dialog>
       <div style={{position: 'fixed',top: '50%', left: '50%', display: searchProgress === true ? "block" : "none" }}>
            <CircularProgress style={{margin:"auto"}}/>
        </div>
     </Container >
   );
 };
 export default OrderEmd;